import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AccountFinderAccountList} from './AccountFinderAccountList';
import {AccountFinderViewProps} from './AccountFinderView';
import {User} from '../../users/user.class';
import Button from '../../ui/Buttons/Button';

interface AccountFinderDistrictProps extends AccountFinderViewProps {
    accountKeys: string[];
    accountOnlyMode: boolean;
    division?: string;
    selectAccount: (accountNum: string, contactID: string) => void;
    setAccountKeys: Dispatch<SetStateAction<string[]>>;
    user: User;
}

export const AccountFinderDistrict = ({
    account,
    accountKeys,
    accountOnlyMode,
    division,
    selectAccount,
    setAccountKeys,
    user,
}: AccountFinderDistrictProps) => {
    const isHidden = accountKeys.indexOf(division) >= 0;
    return (
        !account.hidden && (
            <div className={isHidden ? `tw-hidden` : `tw-block`}>
                {account.account && (
                    <div className="tw-flex tw-justify-between tw-bg-gray-100 tw-border-t tw-p-4">
                        <div className="tw-flex tw-items-center">
                            <div className="tw-mx-4">
                                {account.accounts.filter(({hidden}) => !hidden).length > 0 && (
                                    <FontAwesomeIcon
                                        icon={accountKeys.indexOf(account.account) >= 0 ? faAngleDown : faAngleUp}
                                        size="xl"
                                        className="tw-cursor-pointer"
                                        onClick={() => {
                                            if (accountKeys.indexOf(account.account) === -1) {
                                                setAccountKeys([...accountKeys, account.account]);
                                            } else {
                                                setAccountKeys(accountKeys.filter((item) => item !== account.account));
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <div className="tw-flex tw-flex-col">
                                <div className="tw-text-base !tw-mb-0 tw-font-bold !tw-capitalize">{account.name?.toLowerCase()}</div>
                                <div className="tw-text-sm tw-font-normal tw-leading-[1.3]">{account.account}</div>
                            </div>
                        </div>
                        {account.hideLogin !== true && (
                            <div>
                                {user.activeAccount === account.account && <>Logged In</>}
                                {user.activeAccount !== account.account && !accountOnlyMode && (
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        className="tw-py-1.5 tw-px-3 "
                                        onClick={() => selectAccount(account.account, account.contact)}
                                    >
                                        Log In
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {account.accounts && (
                    <AccountFinderAccountList
                        account={account}
                        accountKeys={accountKeys}
                        accountOnlyMode={accountOnlyMode}
                        selectAccount={selectAccount}
                        user={user}
                    />
                )}
            </div>
        )
    );
};

import * as React from 'react';

import {showChatWindow} from '../../contacts/contact.functions';

export const FocusedFooter = () => (
    <>
        <div className="tw-p-4">
            <div className="tw-flex tw-flex-wrap tw-mb-4">
                <div className="tw-text-center tw-max-w-full tw-flex-grow tw-relative tw-w-full">
                    <ul className="tw-text-center tw-w-full tw-list-none !tw-mb-0 tw-p-0">
                        <li className="tw-pr-1.5 tw-inline">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="tel:1-800-558-2808"
                            >
                                Call 1-800-558-2808
                            </a>
                        </li>
                        <li className="tw-border-l tw-border-l-gray-200 tw-px-1.5 tw-inline">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="/contact-us"
                            >
                                Contact Us
                            </a>
                        </li>
                        <li className="tw-inline tw-border-l tw-border-l-gray-200 tw-px-1.5 md:tw-hidden md:tw-px-0 md:tw-border-none">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    showChatWindow();
                                }}
                            >
                                Chat Now
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap">
                <div className="tw-text-center tw-max-w-full tw-flex-grow tw-relative tw-w-full">
                    <ul className="tw-text-center tw-w-full tw-list-none !tw-mb-0 tw-p-0">
                        <li className="tw-pr-1.5 tw-inline">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="/content/privacy"
                            >
                                Privacy Policy
                            </a>
                        </li>
                        <li className="tw-border-l tw-border-l-gray-200 tw-px-1.5 tw-inline">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="/content/terms"
                            >
                                Terms of Sale
                            </a>
                        </li>
                        <li className="tw-border-l tw-border-l-gray-200 tw-px-1.5 tw-inline">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="/content/terms-of-access"
                            >
                                Terms of Access
                            </a>
                        </li>
                        <li className="tw-border-l tw-border-l-gray-200 tw-px-1.5 tw-inline">
                            <a
                                id="ot-sdk-btn"
                                className="tw-text-black hover:tw-no-underline ot-sdk-show-settings tw-cursor-pointer"
                            >
                                Your Privacy Choices
                            </a>
                        </li>
                        <li className="tw-border-l tw-border-l-gray-200 tw-px-1.5 tw-inline">
                            <a
                                className="tw-text-black hover:tw-no-underline"
                                href="/help"
                            >
                                Help
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tw-text-black tw-text-center">© {new Date().getFullYear()} Imperial Supplies LLC. All Rights Reserved.</div>
        </div>
        <div
            className="tw-fixed tw-bottom-5 !tw-z-[1029] !tw-cursor-pointer tw-right-[7px] print:tw-hidden tw-hidden md:tw-block"
            data-id="TTgNdamL2Yv"
        >
            <button
                className="tw-hidden md:!tw-block tw-no-underline tw-bg-transparent tw-pe-0"
                onClick={showChatWindow}
                type="button"
            >
                <img
                    alt="Chat Now"
                    className="tw-w-[60px] tw-h-[61px] tw-p-0 tw-overflow-hidden tw-align-middle"
                    src="https://cdn.livechat-files.com/api/file/lc/main/7582451/0/button/online/f939d0da0783996807fdf3ed337ce12f.png"
                />
            </button>
        </div>
    </>
);

import * as React from 'react';
import {Card} from 'react-bootstrap';

import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {Alerts} from '../../ui/Alerts/Alerts';
import {DisplayCurrencyFormatted} from '../display-currency-formatted/DisplayCurrencyFormatted';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {Item} from '../../items/item.class';
import {SavedForLaterItem} from '../order.types';

interface SavedForLaterItemCardProps {
    canViewPrice: boolean;
    onAddSavedForLaterItemToOrder: (item: string, qty: number) => void;
    onDeleteSavedForLaterItem: (item: string) => void;
    orderNbr: string;
    position: number;
    savedForLaterItem: SavedForLaterItem;
}

export const savedForLaterGaItemListName: GaItemListName = `saved_for_later`;

export const SavedForLaterItemCard = ({
    canViewPrice,
    onAddSavedForLaterItemToOrder,
    onDeleteSavedForLaterItem,
    orderNbr,
    position,
    savedForLaterItem,
}: SavedForLaterItemCardProps) => {
    /**
     * Template
     */
    return (
        <Card
            className="w-100 mb-3"
            key={savedForLaterItem.item}
        >
            <Card.Body>
                {!savedForLaterItem.inStock && (
                    <Alerts
                        message="This item is out of stock."
                        variant="warning"
                    />
                )}
                <div className="d-flex">
                    <div className="d-none d-md-flex flex-md-column">
                        <ImpressionTracker
                            data={{
                                dimension16: orderNbr,
                                index: position,
                                item_id: savedForLaterItem.item,
                                item_list_name: savedForLaterGaItemListName,
                            }}
                        >
                            <a
                                href={`/item/${savedForLaterItem.item}`}
                                className="pl-1 pr-7 d-block"
                            >
                                <img
                                    alt={`${savedForLaterItem.desc}`}
                                    height="120"
                                    width="120"
                                    src={`/ProductImageThumbs120${savedForLaterItem.imagePath || '/noimage.png'}`}
                                />
                            </a>
                        </ImpressionTracker>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column">
                        <div className="d-flex flex-row">
                            <div className="flex-grow-1">
                                <ImpressionTracker
                                    data={{
                                        dimension16: orderNbr,
                                        index: position,
                                        item_id: savedForLaterItem.item,
                                        item_list_name: savedForLaterGaItemListName,
                                    }}
                                    trackImpressions={false}
                                >
                                    <a
                                        className="gray-650"
                                        href={`/item/${savedForLaterItem.item}`}
                                    >
                                        #{Item.prettyItem(savedForLaterItem.item)}
                                    </a>
                                </ImpressionTracker>
                                <ImpressionTracker
                                    data={{
                                        dimension16: orderNbr,
                                        index: position,
                                        item_id: savedForLaterItem.item,
                                        item_list_name: savedForLaterGaItemListName,
                                    }}
                                    trackImpressions={false}
                                >
                                    <a
                                        className="gray-650"
                                        href={`/item/${savedForLaterItem.item}`}
                                    >
                                        <div
                                            className="h5 font-weight-bold mb-3 d-none d-md-block"
                                            dangerouslySetInnerHTML={{
                                                __html: savedForLaterItem.desc,
                                            }}
                                            style={{
                                                lineHeight: 1.5,
                                            }}
                                        ></div>
                                        <div
                                            className="d-block d-md-none mb-3"
                                            dangerouslySetInnerHTML={{
                                                __html: savedForLaterItem.desc,
                                            }}
                                        ></div>
                                    </a>
                                </ImpressionTracker>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="flex-grow-1">
                                <ImpressionTracker
                                    data={{
                                        dimension16: orderNbr,
                                        index: position,
                                        item_id: savedForLaterItem.item,
                                        item_list_name: savedForLaterGaItemListName,
                                    }}
                                    trackImpressions={false}
                                >
                                    <a
                                        href={`/item/${savedForLaterItem.item}`}
                                        className="pl-1 pr-4 my-3 d-block d-md-none"
                                    >
                                        <img
                                            alt={`${savedForLaterItem.desc}`}
                                            height="120"
                                            width="120"
                                            src={`/ProductImageThumbs120${savedForLaterItem.imagePath || '/noimage.png'}`}
                                        />
                                    </a>
                                </ImpressionTracker>
                            </div>
                            <div className="flex-column">
                                <div className="text-right">
                                    Pkg Qty: {savedForLaterItem.pkgQty}
                                    <div className="d-md-none mb-2" />
                                    <span className="d-none d-md-inline"> | </span>
                                    Order Qty: {savedForLaterItem.qty}
                                </div>
                                {canViewPrice && savedForLaterItem.amount && (
                                    <div className="h5 font-weight-bold text-right mt-2 mb-0">
                                        <DisplayCurrencyFormatted amount={savedForLaterItem.amount} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row mt-3">
                            <div className="flex-grow-1 row">
                                <div className="col-6 col-md-5 col-xl-3">
                                    <button
                                        className="btn btn-red btn-block"
                                        data-e2e="addToOrderBtnSaveForLater"
                                        onClick={() => {
                                            onAddSavedForLaterItemToOrder(savedForLaterItem.item, savedForLaterItem.qty);
                                        }}
                                    >
                                        Add to Order
                                    </button>
                                </div>
                                <div className="col-6 col-md-5 col-xl-3">
                                    <button
                                        aria-label="Delete Item"
                                        className="btn btn-outline-secondary btn-block"
                                        data-e2e="removeBtnSaveForLater"
                                        onClick={() => {
                                            onDeleteSavedForLaterItem(savedForLaterItem.item);
                                        }}
                                        role="button"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                            <div className="body text-right mb-3 mb-md-0 px-8" />
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

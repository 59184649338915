import * as React from 'react';

import {CategorySuggestion} from '../../search/search-suggestions/search-suggestions.class';
import {gaLegacySiteSearch} from '../../../client/ga/ga-legacy.functions';
import {ProductImage} from '../../ui/ProductImage';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {SignalsService} from '../../../client/search/signals/signals.service';
import {useService} from '../../react/ServiceContext';

interface SearchOverlayCategorySuggestionProps {
    categorySuggestion: CategorySuggestion;
    currentCategoryQueryID: string;
    index: number;
    recordSearchTerm: () => void;
    searchString: string;
}

const MAX_MOBILE_RESULTS = 6;

export const SearchOverlayCategorySuggestion = ({
    categorySuggestion,
    currentCategoryQueryID,
    index,
    recordSearchTerm,
    searchString,
}: SearchOverlayCategorySuggestionProps) => {
    const signalsService: SignalsService = useService(`signalsService`);

    /**
     * Template
     */
    return (
        <div
            className={`lg:!tw-w-full lg:tw-mb-4 lg:tw-relative lg:tw-px-2.5 ${index >= MAX_MOBILE_RESULTS ? 'tw-hidden lg:tw-block' : ''}`}
        >
            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-white tw-border-t lg:tw-border tw-rounded-sm tw-h-full">
                <div className="tw-flex tw-py-2 tw-px-4 ">
                    <a
                        className="tw-no-underline tw-flex tw-items-center"
                        href={categorySuggestion.url}
                        onClick={() => {
                            recordSearchTerm();
                            gaLegacySiteSearch(searchString, `typeahead_search`, true);
                            signalsService
                                .sendSignals([
                                    {
                                        params: {
                                            collection: `productCategories`,
                                            docId: categorySuggestion.legacyWebcatID,
                                            fusion_query_id: currentCategoryQueryID,
                                            res_pos: index + 1,
                                            source: `SearchOverlay`,
                                        },
                                        type: `click`,
                                    },
                                ])
                                .catch((sendSignalsErr) => {
                                    // fail silently, most likely a navigation before signals finish saving
                                    // eslint-disable-next-line no-console
                                    console.log(`Error sending fusion signals`, sendSignalsErr);
                                });
                            pushGaEvent(`ga4_search_overlay_click`, {
                                click_type: `search_overlay_categories`,
                                search_input: searchString,
                                selection_name: categorySuggestion.name,
                            });
                        }}
                    >
                        <ProductImage
                            altText={categorySuggestion.name}
                            containerClass="lg:tw-text-center lg:tw-align-middle lg:tw-block tw-hidden tw-float-left tw-mr-4"
                            size={50}
                            src={categorySuggestion.imageAssetLargeThumb}
                            srcIncludesImgThumbsPath
                        />
                        <div className="tw-w-full tw-whitespace-normal">
                            <span className="tw-text-black lg:tw-text-blue-200 tw-text-base tw-mb-2 tw-font-normal tw-leading-[1.2]">
                                {categorySuggestion.name}
                            </span>
                            {categorySuggestion.parentName && (
                                <div className="tw-text-sm tw-font-normal tw-leading-[1.3] tw-text-gray-400">
                                    in {categorySuggestion.parentName}{' '}
                                    {categorySuggestion.numItems && (
                                        <>
                                            {''}({categorySuggestion.numItems})
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

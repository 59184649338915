import * as React from 'react';

interface SpinnerProps {
    className?: string;
}

export const Spinner = ({className}: SpinnerProps) => (
    <div
        className={`tw-block tw-border-4 tw-border-gray100 tw-border-r-gray-250 tw-border-l-gray-250 tw-border-b-gray-250 tw-rounded-[50%] tw-w-8 tw-h-8 tw-animate-spin tw-duration-75 tw-mt-4 tw-mx-auto${
            className ? ' ' + className : ''
        }`}
    />
);

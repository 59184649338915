import {Exceptions} from '../exceptions/exceptions.class';
import {StandardJSONResponse} from '../tools/AS.types';
import {PriceInfo} from '../items/item.class';

export const COMPACTVIEWTHRESHOLD = 49;
export const validOrderRegexString = `[P|R]([A-Z0-9]{5}|[A-Z0-9]{1})[0-9]{4}`;
export type ApprovalOrderAction = `approve` | `decline` | `delete`;
export type CheckoutSource = `checka.asp` | `checks.asp`;
export type DownloadOrderItemType = `full` | `simple`;
export type MobileType = `a` | `w`;
export type OrderLineSortOrder = `amount_0` | `amount_9` | `entNbr_9` | `item_a` | `item_z` | `name_a` | `name_z`;
export type OrderViewType = `compact` | `standard`;
export type SavedForLaterAction = 'add' | 'remove';
type OrderLineAlertType =
    | `bulk-price`
    | `dropship-frt`
    | `dropship`
    | `ds-canada`
    | `first-time-buy`
    | `item-split`
    | `logistics`
    | `low-stock`
    | `no-pri-split`
    | `on-demand`
    | `out-of-stock`
    | `program-fee`
    | `special-price`
    | `third-party`
    | `ups-hazardous`;
export type PCodeType = 'freeItem' | 'freeShipping' | 'giftCard' | 'itemDiscount' | 'ordrDiscount';
export type ApprovalOrderSortOrder =
    | `amount_0`
    | `amount_z`
    | `lastUpdate_a`
    | `lastUpdate_z`
    | `ordrLogin_a`
    | `ordrLogin_z`
    | `ordrNbr_a`
    | `ordrNbr_z`;

export interface ApprovalLevels {
    level1?: Approver[] | null;
    level2?: Approver[] | null;
    level3?: Approver[] | null;
}
export interface Approver {
    approver: string;
    apprvStatus: string;
    date?: null;
    approved: boolean;
    required: boolean;
    name: string;
    level: number;
}

export interface ApprovalAccount {
    account: string;
    city: string;
    name: string;
    orders: ApprovalOrder[];
    siteid: string;
}

export interface ApprovalAccountState {
    hiddenOrders: number;
}

export interface ApprovalListRes {
    accounts: ApprovalAccount[];
    approvalOrderCount: number;
    result: string;
}

export interface ApprovalOrder {
    name: string;
    account: string;
    actionNeeded: boolean;
    amount: number;
    approvals?: ApprovalLevels[];
    approved: boolean;
    comment?: string;
    imagePath: string[];
    images: string[];
    lastUpdate: string;
    ordrDt: string;
    ordrLines: number;
    ordrLogin: string;
    ordrNbr: string;
    po: string;
    remarks: Remarks[];
    status: `In Process` | `Order Declined` | `Pending Approval`;
}

export interface ApproveOrdersParams {
    account: string;
    emailCopy: boolean;
    login: string;
    orders: OrderToApprove[];
    security: string;
}

export interface ApprovalOrderState {
    checked: boolean;
    hasCheckbox: boolean;
    hidden: boolean;
    msg: string[];
    order?: string;
    remark: string;
    result: string;
}

export interface Backorder {
    account: string;
    accountCode: string;
    badge: string;
    billTo: ImpAddress;
    costCenter: string;
    defaultShipVia: string;
    isFedex: boolean;
    lines?: BackorderLine[];
    location: string;
    loginName: string;
    nonstock: boolean;
    orderReadOnly: boolean;
    ordProc: string;
    ordrDt: string;
    ordrLines: number;
    ordrNbr: string;
    origInvoice: string;
    payterm: string;
    po: string;
    project: string;
    rep: Rep;
    result: string;
    shipTo: ImpAddress;
    shipVia: string;
    shipViaDesc?: string;
    status: string;
    validShipVias: string;
}

export interface BackorderLine {
    amount: number;
    assortment: boolean;
    auto: boolean;
    boxes: number;
    etaDt: string;
    excludeSt?: string[];
    imagePath: string;
    item: string;
    name: string;
    pkgQty: number;
    prcbk: string;
    prettyid: string;
    priceC: number;
    pricePK: number;
    qty: number;
}

export interface CheckoutForm {
    'acct-code'?: string;
    'ce-ref': string;
    'cost-center'?: string;
    'ship-via': `A` | `D` | `E` | `F` | `W` | `X` | `Y` | `Z` | `1` | `2` | `3` | `5` | `9`;
    _csrf: string;
    allowancePcode?: string;
    allowancePcodeApplied?: `true` | `false`;
    apprvcmt?: string;
    badge?: string;
    div848?: boolean;
    emailcopy: `on` | `off`;
    emailshipping: `on` | `off`;
    location: string;
    nonrev?: string;
    payvia: string;
    pcode?: string;
    po: string;
    project?: string;
    taxable?: `true` | `false`;
}

export interface CheckRestrictedItemsRes {
    deleted: boolean;
    deletedItems: DeletedRestrictedItem[];
    result: 'OK';
}

export interface CreateOrderParams {
    account: string;
    isPunchout: boolean;
    loginName: string;
    mobileType: MobileType;
    punchOutId: string;
    securityString: string;
}

export interface CreateOrderRes {
    account: string;
    billTo: ImpAddress;
    cid: number;
    location: string;
    ordrDt: string;
    ordrNbr: string;
    po: string;
    punchTo?: ImpAddress;
    result: string;
    shipTo: ImpAddress;
    shipvia: string;
    validShipVias?: string;
}

export interface DcDetails {
    name: string;
    number: number;
    priority: number;
    recStatus?: string;
    shipDt: string;
    shipNbr: string;
}

export interface DeleteOrderRes {
    ok?: boolean;
    error?: string;
}

export interface DeletedRestrictedItem {
    image: string;
    imagePath: string;
    item: string;
    name: string;
    pkgQty: number;
    qtyOrd: number;
}

export interface GetSpecialOrdersRes {
    orders: SpecialOrder[];
    result: string;
}

export interface GetTrackingInfoRes {
    addr1: string;
    addr2: string;
    attn: string;
    city: string;
    date: string;
    dcs?: Dcs[] | null;
    externalErrorMessage?: string;
    invoice: string;
    msg: string;
    name: string;
    order: string;
    ordType: string;
    result: string;
    siteId: string;
    st: string;
    status: string;
    zip: string;
}

export interface Dcs {
    dc: number;
    dcName: string;
    images?: string[] | null;
    itemCnt: number;
    packageCnt: number;
    packages?: Packages[] | null;
}

export interface Packages {
    trackNbr: string;
    shipDate: string;
    shipDesc: string;
    shipVia: string;
    vendorName: string;
    vendorURL: string;
    trackURL: string;
    itemCnt: number;
    images?: string[];
    shipmentDetails: ShipmentDetails;
}

export interface ImpAddress {
    ['ctry-code']?: string;
    addr1: string;
    addr2: string;
    attn: string;
    city: string;
    name: string;
    residential?: boolean;
    st: string;
    zip: string;
}

export interface ImpRep {
    email: string;
    login: string;
    name: string;
    phone: string;
    rep: number;
}

export interface ImpSubtotal {
    amount: number;
    id: number;
    name: string;
}

export interface ApproveOrderRes {
    msgs: string[];
    order: string;
    result: `Error` | `OK`;
}

export interface OrderApprovalFilter {
    showOrderDeclined: boolean;
    showPendingApproval: boolean;
    showMyPendingApproval: boolean;
}

export interface OrderApprovalForm {
    approvalAccounts: {[`accountNumber`]?: ApprovalAccountState};
    approvalOrders: {[`orderNumber`]?: ApprovalOrderState};
    emailCopy: boolean;
    numSelected: number;
    selectAll: boolean;
}

export interface OrderAsXML {
    ACCOUNT_NUMBER: string;
    EMAIL: string;
    NAME: string;
    ORDER_FREIGHT: number;
    ORDER_SUBTOTAL: number;
    ORDER_TAX: number;
    ORDER_TOTAL_COST: number;
    PRICED_ITEMS_NTAB: OrderLineAsXML[];
}

export interface OrderCharge {
    amount: number;
    code: number;
    estDiscAmt?: number;
    name: string;
    percent?: number;
}

export interface OrderLineAlert {
    days?: number;
    itemQtyLimit?: number;
    pandemicItem?: boolean;
    pandemicItemBlocked?: boolean;
    special?: number;
    type: OrderLineAlertType;
}

export interface OrderLine {
    alerts?: OrderLineAlert[];
    amount: number;
    bin?: string[];
    campid?: number;
    canDiscount?: boolean;
    canShipAir: boolean;
    checked: boolean;
    custPunchItem?: string;
    dc: DcDetails;
    entNbr: number;
    exceptions: Exceptions;
    excludeSt?: string[];
    extraDesc?: string;
    fullWebcat: string;
    graMsdsavail_b: boolean;
    GSDP_ID_s: string;
    image: string;
    imagePath: string;
    item: string;
    itemNeedsAppr?: boolean;
    lotTrack: boolean;
    manItem?: string;
    manName?: string;
    manual: boolean;
    messages?: string[];
    mroStatus?: boolean;
    mroStatusMessage?: string;
    name: string;
    onBackorder?: string;
    orderLineError?: string;
    ormd: boolean;
    pkgQty: number;
    prcbk: string;
    prettyid: string;
    priceC: number;
    pricePK?: number;
    pricePlatform?: number;
    promo: boolean;
    prop65b_b: boolean;
    prop65b_chemicals_s?: string;
    prop65c_b: boolean;
    prop65Message?: string;
    qtyLimit: number;
    qtyOrd: number;
    qtyShp: number;
    quantityPlatform?: number;
    sdsLink?: string;
    subtotId?: number;
    surcharge?: boolean;
    type: string;
    unitsOrdered: number;
    uomPlatform?: string;
    upsHazard: boolean;
}

export interface OrderLineAsXML {
    MR_PRICED_ITEMS_TYP: {
        EXTENDED_LINE_ITEM_PRICE: number;
        ITEM_DESCRIPTION: string;
        ITEM_NUMBER: string;
        PACKAGE_QUANTITY: number;
        UNITS_ORDERED: number;
    };
}

export interface Remark {
    action: string;
    comment: string;
    seqNbr: number;
    time: string;
    user: string;
}

export interface Remarks {
    date: string;
    remark: Remark[];
}

export interface SavedForLaterItem {
    amount: number;
    createDatetime: string;
    desc: string;
    discontinued: boolean;
    id: string;
    image: string;
    imagePath: string;
    inStock: boolean;
    item: string;
    pandemicItem?: boolean;
    pandemicItemBlocked?: boolean;
    pkgQty: number;
    qty: number;
}

export interface ShareOrderItemsListIdRes {
    listId: number;
    result: `OK`;
}

export interface ShipmentDetails {
    delayReason?: string;
    deliveryDate?: string;
    errorCode?: string;
    errorMessage?: string;
    estimatedArrival?: string;
    receivedBy?: string;
    signedBy?: string;
    status: 'Shipping' | 'Out of Delivery' | 'Delivered' | 'Delayed' | 'Unknown';
    statusDetails?: EventDay[];
}

export interface EventDay {
    events: ShipmentEvent[];
    date: string;
}

export interface ShipmentEvent {
    eventLocation?: string;
    eventDescription: string;
    date: string;
}

export interface SpecialOrder {
    account: string;
    amount: number;
    automatic: boolean;
    items: SpecialOrderItem[];
    nonstockOrdr: boolean;
    ordrDt: string;
    ordrNbr: string;
    ordrPo: string;
    ordrProcessDt?: null;
    origInvc: string;
    origShipDt: string;
    prepaidFrt: boolean;
    repInfo: Rep;
    shipto: ImpAddress[];
}

export interface SpecialOrderItem {
    amount: number;
    automatic: boolean;
    canShipAir: boolean;
    desc1: string;
    desc2: string;
    exceptions: Exceptions;
    extraDesc: string;
    item: string;
    lotTracking: boolean;
    lqtyProd: boolean;
    origQtyOrd: number;
    pkgQty: number;
    priceC: number;
    qty: number;
    sdsLink?: string;
    shortDesc: string;
    type: string;
    upsHaz: boolean;
}

export interface OrderToApprove {
    action: ApprovalOrderAction;
    comment: string;
    order: string;
    po?: string;
}

export interface Rep {
    login?: string;
    loginName?: string;
    name: string;
    rep: number;
}

export interface SharedOrderItems {
    ezid: number;
    itemCnt: number;
    items?: ItemsEntity[] | null;
    result: string;
}

export interface SplitOrderParams {
    account?: string;
    login?: string;
    order: string;
    splitItems: string[];
}

export interface SubmitQuoteParams {
    account?: string;
    comment: string;
    coName?: string;
    fullName?: string;
    login?: string;
    order: string;
    phone: string;
    securityString?: string;
}

export interface SplitOrderRes extends StandardJSONResponse {
    newOrder: string;
}

export interface ItemsEntity {
    binLocation: string;
    brand: string;
    description: string;
    fullWebcat: string;
    imagePath: string;
    item: string;
    pkgQty: number;
    prettyItem: string;
    priceInfo: PriceInfo;
    quantity: number;
    selected?: boolean;
    unitsOrdered: number;
    wid: number;
}

export interface UpdateOrderParams {
    ['ship-via']?: string;
    account?: string;
    addr1?: string;
    addr2?: string;
    attn?: string;
    btaddr1?: string;
    btaddr2?: string;
    btattn?: string;
    btcity?: string;
    btname?: string;
    btst?: string;
    btzip?: string;
    city?: string;
    ctrycode?: string;
    forcetax?: boolean;
    location?: string;
    loginname?: string;
    mobile?: MobileType;
    name?: string;
    order?: string;
    po?: string;
    saveaddr?: 'no' | 'yes';
    shipmethods?: string;
    st?: string;
    zip?: string;
}

export interface VocType {
    reqImage: boolean;
    reqWebCond: boolean;
    reshipReq: boolean;
    commentReq: boolean;
    type: string;
    typeDesc: string;
}

export interface VocCondition {
    condition: string;
    conditionDesc: string;
}

export interface ReturnItem {
    condition: string;
    dc: string;
    files: number;
    item: string;
    itemComment?: string;
    reship: string;
    returnQty: number;
    selectItem: boolean;
    type: string;
}

export interface UpdateSavedForLaterItemsRes {
    result: string;
}

import * as React from 'react';
import {debounce} from 'lodash';
import {faSearch, faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {signal} from '@preact/signals-react';
import {useCallback, useEffect, useRef, useState} from 'react';

import {Alerts} from '../../ui/Alerts/Alerts';
import {BodyScroll} from '../../ui/ScrollHandler';
import {gaLegacyCustomEvent, gaLegacySiteSearch} from '../../../client/ga/ga-legacy.functions';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ItemSpinner} from '../../ui/item-spinner/ItemSpinner';
import {ItemsService} from '../../../client/items/items.service';
import {LocalStorageService} from '../../../client/local-storage/local-storage.service';
import {MROResult} from './MROResult';
import {NullResults} from './NullResults';
import {OrderableItem, PriceInfo} from '../../items/item.class';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {ProductSuggestion, SearchSuggestions} from '../../search/search-suggestions/search-suggestions.class';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {SearchOverlayResults} from './SearchOverlayResults';
import {SearchService} from '../../../client/search/search.service';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import Button from '../../ui/Buttons/Button';

interface SearchOverlayProps {
    dataE2e: string;
    initialSearchString: string;
    screenSize: `desktop` | `mobile`;
    user: User;
}

const cursorPosition = signal([0, 0]);
const KEY_DEBOUNCE_DELAY = 300;
const MAX_DISPLAY_TERMS = 12;
const MAX_STORE_TERMS = 50;
const MIN_SEARCH_TERM_LEN = 3;

export const SearchOverlay = ({dataE2e, initialSearchString, screenSize, user}: SearchOverlayProps) => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [recentlyViewedItems, setRecentlyViewedItems] = useState<OrderableItem[]>();
    const [searchError, setSearchError] = useState<string>();
    const [searchResults, setSearchResults] = useState<SearchSuggestions>();
    const [searchString, setSearchString] = useState(initialSearchString || ``);
    const [searchTerms, setSearchTerms] = useState<string[]>();
    const componentName = `SearchOverlay-${screenSize}`;
    const itemsService: ItemsService = useService(`itemsService`);
    const localStorageService: LocalStorageService = useService(`localStorageService`);
    const mainInputRef = useRef<HTMLInputElement>();
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const searchButtonRef = useRef<HTMLButtonElement>();
    const searchService: SearchService = useService(`searchService`);
    const userStateService: UserStateService = useService(`userStateService`);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAddToOrder(componentName)) {
            orderItemsWorkflow.addToOrderModal(userStateService.addToOrderItems, componentName, true);
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Get array of recent search terms from local storage
     */
    const _getSearchTerms = useCallback(
        (searchTerm: string): string[] => {
            let termHistoryArray = [];
            const filteredTermHistoryArray = [];
            const termHistoryString = localStorageService.getItem(`termHistory`);

            // Convert termHistoryString to termHistoryArray
            if (termHistoryString) {
                termHistoryArray = termHistoryString.split(`,`).reverse();
            } else {
                return termHistoryArray;
            }
            if (!searchTerm) {
                return termHistoryArray.slice(0, MAX_DISPLAY_TERMS);
            }

            // Filter termHistoryArray using searchTerm
            const searchStringRegex = TemplateHelpers.regexSafeFilterVal(searchTerm);
            for (const nextSearchTerm of termHistoryArray) {
                if (nextSearchTerm.match(searchStringRegex)) {
                    filteredTermHistoryArray.push(nextSearchTerm);
                }
            }
            return filteredTermHistoryArray;
        },
        [localStorageService],
    );

    /**
     * Load prices for recent search result
     */
    const _getSuggestedProductPrices = useCallback(
        (_searchSuggestions: SearchSuggestions) => {
            const combinedProductSuggestions = _searchSuggestions.products.concat(_searchSuggestions.partNumbers);
            if (combinedProductSuggestions.length) {
                itemsService
                    .addPricesForItemTypeArray(combinedProductSuggestions)
                    .then((getPricesFromItemNumArrayRes: ProductSuggestion[]) => {
                        const priceMap: Map<string, PriceInfo> = new Map();
                        getPricesFromItemNumArrayRes.forEach((suggestionResult) => {
                            priceMap.set(suggestionResult.id, suggestionResult.priceInfo);
                        });
                        const newSuggestions = {..._searchSuggestions};
                        if (newSuggestions.products) {
                            newSuggestions.products.forEach((suggestionResult) => {
                                suggestionResult.priceInfo = priceMap.get(suggestionResult.id);
                            });
                        }
                        if (newSuggestions.partNumbers) {
                            newSuggestions.partNumbers.forEach((suggestionResult) => {
                                suggestionResult.priceInfo = priceMap.get(suggestionResult.id);
                            });
                        }
                        setSearchResults(newSuggestions);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.error(e);
                    });
            }
        },
        [itemsService],
    );

    /**
     * Closes search overlay
     */
    const closeOverlay = () => {
        setOverlayOpen(false);
        BodyScroll(true);
        pushGaEvent(`ga4_search_overlay_close`, {click_type: `modal_close`});
    };

    /**
     * Execute search query
     */
    const executeSearch = useCallback(
        (newSearch: string) => {
            setSearchError(undefined);
            searchService
                .getSearchSuggestions(newSearch)
                .then((getSearchSuggestionsRes) => {
                    if (!getSearchSuggestionsRes) {
                        // If results are canceled before returning (a 2nd request sent before first returns) we get a blank callback
                        return;
                    }
                    setSearchResults(getSearchSuggestionsRes);
                    _getSuggestedProductPrices(getSearchSuggestionsRes);
                    if (
                        getSearchSuggestionsRes &&
                        getSearchSuggestionsRes.categories.length === 0 &&
                        getSearchSuggestionsRes.partNumbers.length === 0 &&
                        getSearchSuggestionsRes.products.length === 0 &&
                        !getSearchSuggestionsRes.mroMatch &&
                        !recentlyViewedItems
                    ) {
                        itemsService
                            .recentlyViewedItems()
                            .then((getRecentlyViewedItemsRes) => {
                                if (getRecentlyViewedItemsRes) {
                                    setRecentlyViewedItems(getRecentlyViewedItemsRes);
                                }
                            })
                            .catch(() => {
                                // Error silently
                            });
                    }
                })
                .catch((getSearchSuggestionsErr: ImpError) => {
                    setSearchError(getSearchSuggestionsErr.message);
                });
        },
        [_getSuggestedProductPrices, searchService, itemsService, recentlyViewedItems],
    );

    /**
     * Open the overlay
     */
    const openOverlay = useCallback(() => {
        pushGaEvent(`ga4_search_overlay_open`, {click_type: `modal_open`});
        setOverlayOpen(true);
        BodyScroll(false);
        if (searchString && !searchResults) {
            executeSearch(searchString);
        } else {
            setSearchTerms(_getSearchTerms(``));
        }
    }, [searchString, searchResults, _getSearchTerms, executeSearch]);

    /**
     * TBD
     * @param event
     */
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === `Escape`) {
            closeOverlay();
            searchButtonRef.current?.focus();
        }
    };

    /**
     * Records analytics associated with searchTerm
     * @param searchTerm
     */
    const recordAnalytics = (searchTerm: string) => {
        searchTerm = searchTerm.replace(/["']/g, ``);
        localStorageService.setItem(`searchTerm`, searchTerm);
        gaLegacyCustomEvent({
            eventAction: `Recent Search Term`,
            eventCategory: `Search Overlay`,
            eventLabel: searchTerm,
        });
        gaLegacySiteSearch(searchTerm, `recent_search`, true);
        pushGaEvent(`ga4_site_search`, {
            search_method: `recent_search_term`,
            search_term: searchTerm,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const trySearch = useCallback(
        debounce((newSearch: string) => {
            if (newSearch.length >= MIN_SEARCH_TERM_LEN) {
                executeSearch(newSearch);
            } else {
                // Hide search results
                setSearchResults(undefined);
                if (!searchTerms) {
                    setSearchTerms(_getSearchTerms(``));
                }
            }
        }, KEY_DEBOUNCE_DELAY),
        [executeSearch, MIN_SEARCH_TERM_LEN, KEY_DEBOUNCE_DELAY, debounce],
    );

    /**
     * TBD
     * @param event
     */
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        trySearch(event.target.value);
    };

    /**
     * Record search term into recent searches in localstorage
     */
    const recordSearchTerm = () => {
        localStorageService.setItem(`searchTerm`, searchString);
        if (searchString) {
            const termHistoryString = localStorageService.getItem(`termHistory`) || ``;
            let termHistoryArray = termHistoryString.split(`,`);
            const termIndex = termHistoryArray.indexOf(searchString);
            if (termIndex === -1) {
                termHistoryArray.push(searchString);
                if (termHistoryArray.length > MAX_STORE_TERMS) {
                    termHistoryArray.shift();
                }
            } else {
                termHistoryArray.splice(termIndex, 1);
                termHistoryArray.push(searchString);
            }
            termHistoryArray = termHistoryArray.filter((term) => {
                return !!term.trim();
            });
            localStorageService.setItem(`termHistory`, termHistoryArray.join(`,`));
            setSearchTerms(termHistoryArray);
        }
    };

    /**
     * Remove search term from localstorage
     * @param searchTerm
     */
    const removeSearchTerm = (searchTerm: string) => {
        const termHistoryString = localStorageService.getItem(`termHistory`) || ``;
        const termHistoryArray = termHistoryString.split(`,`);
        termHistoryArray.splice(termHistoryArray.indexOf(searchTerm.trim()), 1);
        gaLegacyCustomEvent({
            eventAction: `Clear Single Recent Search Term`,
            eventCategory: `Search Overlay`,
            eventLabel: searchTerm.trim(),
        });
        localStorageService.setItem(`termHistory`, termHistoryArray.join(`,`));
        setSearchTerms(termHistoryArray);
    };

    /**
     * Clear all search terms from local storage
     */
    const clearAllSearchTerms = () => {
        gaLegacyCustomEvent({
            eventAction: `Clear All Recent Search Terms`,
            eventCategory: `Search Overlay`,
            eventLabel: `NA`,
        });
        localStorageService.setItem(`termHistory`, ``);
        setSearchTerms(undefined);
    };

    /**
     * Template
     */
    return (
        <>
            {overlayOpen && (
                <>
                    <div
                        className=" tw-h-full tw-fixed tw-w-full tw-z-[3900] tw-left-0 tw-top-0 tw-bg-gray-650 tw-bg-opacity-70"
                        onClick={() => {
                            closeOverlay();
                            searchButtonRef.current?.focus();
                        }}
                    />
                    <div className="tw-absolute tw-top-0 lg:tw-top-[46px] tw-w-full lg:tw-w-[970px] xl:tw-w-[1200px] tw-left-0 tw-my-0 tw-mx-auto tw-right-0 !tw-z-[4000]">
                        <div className="tw-bg-gray-100 lg:tw-bg-transparent tw-sticky tw-top-0 tw-z-[1025] lg:tw-relative lg:tw-z-auto lg:tw-top-auto tw-py-2">
                            <form
                                action="/search/"
                                className="tw-z-[400] tw-m-0 tw-items-center tw-flex searchOverlay__searchForm"
                                method="GET"
                                onSubmit={() => {
                                    recordSearchTerm();
                                    pushGaEvent(`ga4_site_search`, {search_method: `standard_search`, search_term: searchString});
                                }}
                            >
                                <div className="tw-flex-nowrap tw-relative tw-flex tw-items-stretch tw-w-[93%] lg:tw-w-full tw-ml-2 lg:tw-ml-0">
                                    <input
                                        aria-label="Search Input Field"
                                        autoComplete="off"
                                        autoFocus={true}
                                        className="tw-flex tw-flex-grow tw-py-2 tw-px-4 tw-leading-normal tw-rounded-[0.2rem] tw-relative tw-mb-0 tw-text-xl tw-h-[52px] tw-font-normal tw-text-gray-700 tw-bg-white tw-border tw-bg-clip-padding tw-border-r-0 tw-outline-0 tw-border-gray-200 tw-pr-0"
                                        data-e2e="searchInput"
                                        id="searchForm__searchInput"
                                        name="SearchString"
                                        onChange={handleOnChange}
                                        onKeyUp={handleKeyUp}
                                        placeholder="Search by keyword or item #"
                                        ref={mainInputRef}
                                        type="text"
                                        value={searchString}
                                        onFocus={(e) => {
                                            e.currentTarget.setSelectionRange(cursorPosition.value[0], cursorPosition.value[1]);
                                        }}
                                    />
                                    <div className="tw-ml-[-1px] tw-flex">
                                        <span
                                            className="tw-justify-center tw-pb-2 tw-pt-2.5 tw-px-4 tw-text-xl tw-leading-normal tw-cursor-pointer tw-mb-0 tw-font-normal tw-bg-white tw-border-y"
                                            onClick={() => {
                                                setSearchString(``);
                                                setSearchResults(undefined);
                                                setSearchTerms(_getSearchTerms(``));
                                                mainInputRef.current?.focus();
                                            }}
                                            style={{width: `64px`, display: searchString ? `block` : `none`}}
                                        >
                                            <FontAwesomeIcon
                                                className="tw-align-middle tw-opacity-80"
                                                icon={faTimesCircle}
                                            />
                                        </span>
                                        <Button
                                            className="!tw-w-[54px] tw-relative tw-z-[2] tw-ml-[-1px]"
                                            e2e="searchSubmit"
                                            size="lg"
                                            type="submit"
                                            variant="primary"
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    className="tw-bg-transparent tw-cursor-pointer tw-w-[30px] tw-py-0 tw-pl-3 tw-pr-4 tw-mr-0 lg:tw-my-0 lg:tw-mr-4"
                                    onClick={() => {
                                        closeOverlay();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className="tw-text-lg lg:tw-text-xl lg:tw-text-gray-100 tw-overflow-visible"
                                        icon={faTimes}
                                        size="3x"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="tw-mb-4 tw-min-h-[100vh] tw-p-0 lg:tw-p-4 tw-bg-white lg:tw-min-h-[500px] tw-w-full tw-overflow-x-hidden tw-h-[calc(100vh-134px)]">
                            {searchError && (
                                <div className="tw-max-w-full tw-p-6 lg:tw-p-2 tw-relative tw-w-full">
                                    <Alerts
                                        message2={`Server returned error: ${searchError}`}
                                        message="Please try again later or contact an Imperial Dedicated Account Adviser for assistance at 1-800-558-2808"
                                        multiLine={true}
                                        title="We cannot return results at this time"
                                        variant="danger"
                                    />
                                </div>
                            )}
                            {searchTerms && !searchString && searchTerms.length > 0 && (
                                <div className="tw-bg-white tw-h-full tw-z-[400] tw-w-full">
                                    <div className="tw-mb-4">
                                        <div className="tw-border-b tw-border-t-none tw-mb-0">
                                            <span className="tw-leading-[42px] tw-float-right tw-pt-2 tw-pr-4 tw-mb-0">
                                                <Button
                                                    onClick={clearAllSearchTerms}
                                                    size="sm"
                                                    variant="link"
                                                >
                                                    Clear All
                                                </Button>
                                            </span>
                                            <h5 className="tw-p-4 tw-mb-0 tw-opacity-60 tw-font-normal tw-text-xl">Recent Searches</h5>
                                        </div>
                                        <div>
                                            <ul className="tw-m-0 tw-p-0 tw-list-none">
                                                {searchTerms.map((searchTerm, index) => (
                                                    <li
                                                        className="tw-border-b tw-place-content-center tw-justify-between tw-items-center tw-flex tw-flex-wrap tw-no-underline tw-py-2 tw-pr-2 tw-pl-4"
                                                        key={index}
                                                    >
                                                        <a
                                                            className="tw-text-black tw-mb-2 tw-font-normal tw-leading-[1.2] tw-flex-grow"
                                                            href={`/search/?SearchString=${searchTerm}`}
                                                            onClick={() => recordAnalytics(searchTerm)}
                                                        >
                                                            {searchTerm}
                                                        </a>
                                                        <FontAwesomeIcon
                                                            className="tw-text-xl tw-opacity-80 tw-mr-2 tw-cursor-pointer"
                                                            icon={faTimesCircle}
                                                            onClick={() => {
                                                                removeSearchTerm(searchTerm);
                                                            }}
                                                            size="2x"
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {searchResults &&
                                searchResults.categories.length === 0 &&
                                searchResults.partNumbers.length === 0 &&
                                searchResults.products.length === 0 &&
                                !searchResults.mroMatch && (
                                    <div className="tw-w-full tw-p-6 lg:tw-p-2 tw-relative">
                                        <div className="tw-mb-4">
                                            <NullResults searchString={searchString} />
                                        </div>
                                        <ItemSpinner
                                            componentName={componentName}
                                            gaItemListName="Recently Viewed"
                                            label="Recently Viewed"
                                            loaded={!!recentlyViewedItems}
                                            maxSlides={5}
                                            orderableItems={recentlyViewedItems}
                                            useAddToOrderModal={true}
                                            user={user}
                                        />
                                    </div>
                                )}
                            {searchResults && searchResults.mroMatch && <MROResult mroMatch={searchResults.mroMatch} />}
                            {searchResults && !searchResults.mroMatch && (
                                <SearchOverlayResults
                                    addToOrderComponentName={componentName}
                                    closeOverlay={closeOverlay}
                                    recordSearchTerm={recordSearchTerm}
                                    searchString={searchString}
                                    searchSuggestions={searchResults}
                                    user={user}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <form
                action="/search/"
                method="GET"
            >
                <div className="tw-flex-nowrap tw-flex tw-relative tw-items-stretch tw-w-full">
                    <input
                        accessKey="s"
                        aria-label="Search Field"
                        autoComplete="off"
                        className="focus:!tw-outline-0 tw-py-2 tw-px-4 tw-leading-normal tw-rounded-sm tw-relative tw-flex tw-min-w-0 tw-mb-0 tw-flex-grow tw-text-gray-650 tw-bg-white tw-border tw-text-base tw-h-[calc(1.5em+1rem+8px)] md:tw-text-xl md:tw-h-[calc(1.5em+1rem+2px)]"
                        data-e2e={dataE2e}
                        name="SearchString"
                        onChange={handleOnChange}
                        onClick={(e) => {
                            const cursorStart = e.currentTarget.selectionStart;
                            const cursorEnd = e.currentTarget.selectionEnd;
                            cursorPosition.value = [cursorStart, cursorEnd];
                            if (overlayOpen === false) {
                                openOverlay();
                            }
                        }}
                        onKeyDown={(e) => {
                            if (overlayOpen === false && e.key !== `Tab`) {
                                e.currentTarget.click();
                            }
                        }}
                        placeholder="Search by keyword or item #"
                        type="text"
                        value={searchString}
                    />
                    <div className="tw-ml-[-1px] tw-flex">
                        <Button
                            aria-label="Search"
                            className="tw-py-2 tw-px-4 tw-leading-normal tw-rounded-sm tw-relative tw-z-[2] "
                            e2e="searchBarBtn"
                            size="md"
                            type="submit"
                            variant="primary"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

import * as React from 'react';
import {ChangeEvent, Ref, useEffect, useState, KeyboardEvent} from 'react';
import {faExclamationCircle, faEyeSlash, faEye} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export type InputProps = {
    additionalRef?: React.MutableRefObject<HTMLInputElement>;
    autoCapitalize?: `on` | `off`;
    autocorrect?: `on` | `off`;
    autoFocus?: boolean;
    className?: string;
    clearMargin?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    displayCounter?: boolean;
    displayCounterInline?: boolean;
    e2e?: string;
    error?: string;
    formatPhoneNumber?: boolean;
    inputMode?: `decimal` | `email` | `none` | `numeric` | `search` | `tel` | `text` | `url`;
    label?: string;
    labelClassName?: string;
    maxLength?: number;
    name: string;
    numbersOnly?: boolean;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeString?: (newString: string) => void;
    onEnter?: (val: string) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e) => void;
    onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
    onShowPassword?: (showPass: boolean) => void;
    options?: {
        size?: `default` | `sm`;
    };
    parentCSS?: string;
    ref?: Ref<unknown> | null;
    showPass?: boolean;
    style?: React.CSSProperties;
    success?: string;
    trimWhitespace?: boolean;
    type?: `hidden` | `number` | `password` | `text` | `email`;
    value?: string | number;
} & JSX.IntrinsicElements[`input`];

const InputComponent = (
    {
        additionalRef,
        autoCapitalize,
        autocorrect,
        autoFocus,
        className = ``,
        clearMargin,
        defaultValue,
        disabled = false,
        displayCounter = false,
        displayCounterInline = false,
        e2e,
        error = null,
        formatPhoneNumber,
        inputMode,
        label,
        labelClassName = ``,
        maxLength,
        name,
        numbersOnly,
        onBlur,
        onChange,
        onChangeString,
        onEnter,
        onFocus,
        onKeyDown,
        onPaste,
        onShowPassword,
        options = {size: `default`},
        parentCSS = ``,
        showPass = false,
        style,
        success,
        trimWhitespace,
        type = `text`,
        value,
        ...other
    }: InputProps,
    ref,
) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const initialLength = typeof value === `string` ? value?.length : 0;
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
        setCharacterCount(initialLength);
    }, [initialLength, value]);

    useEffect(() => {
        setShowPassword(showPass);
    }, [showPass]);

    /**
     * Determines if value has exceeded maxLength
     * @param e
     */
    const maxLengthCheck = (e) => {
        if (e.target.value.length > maxLength) {
            e.preventDefault();
            return false;
        }
        return true;
    };
    const formatPhone = (e: ChangeEvent<HTMLInputElement>) => {
        // If formatPhoneNumber, format as phone number
        if (formatPhoneNumber) {
            if (e.target.value.length === 10 && e.target.value[0] !== `1`) {
                e.target.value = e.target.value.replace(/(\d{3})(\d{3})(\d{4})/, `($1) $2-$3`);
            } else if (e.target.value.length === 11 && e.target.value[0] === `1`) {
                e.target.value = e.target.value.replace(/1(\d{3})(\d{3})(\d{4})/, `1 ($1) $2-$3`);
            }
        }
    };

    /**
     * Handles onKeyDown for Input
     * @param e
     */
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) {
            onKeyDown(e);
        }
        // If onEnter provided, invoke at this time
        if (onEnter && e.key === `Enter`) {
            onEnter(value.toString());
        }
    };

    /**
     * Handles onBlur for Input
     * @param e
     */
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        // If onBlur provided, invoke at this time
        if (onBlur) {
            onBlur(e);
        }
    };

    /**
     * Handles onChange for Input
     * @param e
     */
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        // If maxLength, prevent input over max length
        if (!maxLengthCheck(e)) {
            return;
        }
        // Apply automatic formatting
        if (e.target.value) {
            // If preventWhitespace, trim on input
            if (trimWhitespace) {
                e.target.value = e.target.value.trim();
            }

            // If numbersOnly, allow only numeric characters
            if (numbersOnly) {
                e.target.value = e.target.value.replace(/[^0-9]/g, ``);
            }
            formatPhone(e);
            setCharacterCount(e.target.value.length);
        }

        // If onChange provided, invoke at this time
        if (onChange) {
            onChange(e);
        }

        // If onChangeString provided, invoke at this time
        if (onChangeString) {
            onChangeString(e.target.value);
        }
    };

    /**
     * Template
     */
    if (type === `hidden`) {
        return (
            <input
                onChange={onChange}
                ref={ref}
                type="hidden"
            />
        );
    }
    return (
        <div className={clearMargin ? `tw-mb-0` : `tw-mb-5`}>
            <div className={`${displayCounterInline ? 'tw-flex tw-flex-row tw-gap-2 tw-items-center ' : ''}tw-w-full`}>
                <div className={`tw-relative ${parentCSS ? ' ' + parentCSS : ''}`}>
                    <input
                        autoComplete="false"
                        id={name}
                        className={`${options.size !== 'sm' ? 'tw-h-[52px]' : 'tw-h-[40px]'} tw-block tw-rounded-sm${
                            label ? ' tw-pt-5 tw-pb-1' : ''
                        } tw-px-3 tw-w-full tw-border-solid focus:tw-ring-2 ${
                            error
                                ? 'tw-border-2 !tw-border-red-500 focus:tw-border-red-500 focus:tw-ring-red-300'
                                : 'tw-border tw-border-gray-200 focus:tw-border-gray-600 focus:tw-ring-sky-200'
                        } tw-appearance-none focus:tw-outline-none tw-peer ${
                            disabled ? 'tw-bg-gray-100 tw-cursor-not-allowed tw-text-gray-400' : 'tw-bg-white tw-text-gray-900'
                        }${className ? ' ' + className : ''}`}
                        autoCapitalize={autoCapitalize}
                        autoFocus={autoFocus}
                        data-e2e={e2e}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        inputMode={inputMode}
                        name={name}
                        onBlur={handleOnBlur}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                        onFocus={(e) => {
                            if (onFocus) {
                                onFocus(e);
                            }
                        }}
                        onPaste={(e) => {
                            if (onPaste) {
                                onPaste(e);
                            }
                        }}
                        placeholder={' '}
                        ref={(htmlInputElement) => {
                            // Handle primary ref
                            if (ref && typeof ref === 'function') {
                                ref(htmlInputElement);
                            } else if (ref && typeof ref === `object`) {
                                ref.current = htmlInputElement;
                            }

                            // Handle additionalRef
                            if (additionalRef && typeof additionalRef === `object`) {
                                additionalRef.current = htmlInputElement;
                            }
                        }}
                        style={style}
                        type={showPassword ? `text` : type}
                        value={value}
                        autoCorrect={autocorrect}
                    />
                    <label
                        htmlFor={name}
                        className={`${
                            options.size !== 'sm' ? 'tw-top-3.5 -tw-translate-y-3' : 'tw-top-2 -tw-translate-y-2'
                            // eslint-disable-next-line max-len
                        } tw-absolute tw-text-md tw-text-gray-400 tw-duration-300 tw-transform tw-scale-90 tw-z-10 tw-origin-[0] peer-focus:tw-left-0 peer-focus:tw-text-gray-600 peer-placeholder-shown:tw-scale-100 peer-placeholder-shown:tw-translate-y-0 peer-focus:tw-scale-90 peer-focus:-tw-translate-y-3 tw-px-3 ${
                            labelClassName ? labelClassName : ''
                        }`.trim()}
                    >
                        {label} {other.required && `*`}
                    </label>
                    {type === `password` && (
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className={`tw-p-4 tw-text-gray-550 tw-top-0 tw-absolute ${
                                error ? '!tw-right-8' : '!tw-right-0'
                            } action !tw-text-lg`}
                            onClick={() => {
                                setShowPassword(!showPassword);
                                if (onShowPassword) {
                                    onShowPassword(showPassword);
                                }
                            }}
                        />
                    )}
                    {error && (
                        <FontAwesomeIcon
                            className={`!tw-text-red-500 !tw-text-xl tw-absolute tw-right-3.5 !tw-top-[15px]`}
                            icon={faExclamationCircle}
                        />
                    )}
                </div>
                {displayCounterInline && (
                    <span className="tw-text-sm tw-text-gray-200 tw-mr-5 tw-whitespace-nowrap">
                        {characterCount} / {maxLength}
                    </span>
                )}
            </div>
            {(error || displayCounter || success) && (
                <div
                    className={`tw-mt-0.5 tw-flex ${
                        error || success ? 'tw-justify-between' : 'tw-justify-end'
                    }tw-absolute tw-max-w-[920px]`}
                >
                    {error && <label className="tw-text-sm tw-text-red-500">{error}</label>}
                    {success && <label className="success caption">{success}</label>}
                    {displayCounter && !displayCounterInline && (
                        <span className="tw-text-right">
                            <span className="tw-text-sm tw-text-gray-400">
                                {characterCount} / {maxLength}
                            </span>
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};
export const Input = React.forwardRef(InputComponent);

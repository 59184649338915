import * as React from 'react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {ReactNode, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './item-spinner.scss';
import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {ErrorModal} from '../../error-handling/ErrorModal';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {LocalStorageService} from '../../../client/local-storage/local-storage.service';
import {MonetateService} from '../../../client/monetate/monetate.service';
import {OrderableItem} from '../../items/item.class';
import {OrdersService} from '../../../client/orders/orders.service';
import {Price} from '../../pricing/Price';
import {replaceHTMLQuotes} from '../../../client/components/helpers/stringHelper';
import {ShipRestrictionsModal} from '../../items/ship-restrictions/ShipRestrictionsModal';
import {useOrderLines} from '../../lib/hooks/use-order-lines';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface ItemSpinnerProps {
    componentName: string;
    gaItemListName: GaItemListName;
    label?: string | ReactNode;
    loaded?: boolean;
    maxSlides?: number;
    orderableItems?: OrderableItem[];
    useAddToOrderModal?: boolean;
    user: User;
}

const renderHeading = (label) => {
    return {__html: label};
};

export const ItemSpinner = ({
    componentName,
    gaItemListName,
    label,
    loaded,
    maxSlides = 7,
    orderableItems,
    useAddToOrderModal,
    user,
}: ItemSpinnerProps) => {
    const localStorageService: LocalStorageService = useService(`localStorageService`);
    const monetateService: MonetateService = useService(`monetateService`);
    const ordersService: OrdersService = useService(`ordersService`);
    const userStateService: UserStateService = useService(`userStateService`);
    const [errorModal, setErrorModal] = useState({
        errorMsg: ``,
        show: false,
    });
    const [shipRestrictionsModal, setShipRestrictionsModal] = useState({
        show: false,
        orderableItem: undefined,
        unitsOrdered: 1,
    });
    const [slidePosition, setSlidePosition] = useState(0);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAddToOrder(componentName)) {
            addToOrder(userStateService.addToOrderItems, null, true, useAddToOrderModal);
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Setup useOrderLines hooks
    const {addToOrder, orderLineErrors} = useOrderLines({
        componentName,
        gaItemListName,
    });

    // Listen to orderErrors
    useEffect(() => {
        if (orderLineErrors?.length > 0) {
            setErrorModal({
                errorMsg: orderLineErrors[0].message,
                show: true,
            });
        }
    }, [orderLineErrors]);

    /**
     * Sends recToken to Monetate
     * @param recToken
     */
    const clickEvents = (recToken: string) => {
        if (recToken) {
            monetateService.sendRecoEvents({
                recToken,
                eventType: `click`,
            });
        }
    };

    /**
     * Template
     */
    if (!loaded) {
        return <div className="spinner" />;
    }
    if (!(orderableItems?.length > 0)) {
        return <></>;
    }
    return (
        <>
            {label && (
                <div
                    className="h4-lockup"
                    dangerouslySetInnerHTML={renderHeading(label)}
                />
            )}
            <Swiper
                breakpoints={{
                    '0': {
                        slidesPerGroup: 1,
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '420': {
                        slidesPerGroup: 2,
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '640': {
                        slidesPerGroup: 3,
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    '768': {
                        slidesPerGroup: 3,
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    '1200': {
                        slidesPerGroup: 3,
                        slidesPerView: maxSlides,
                        spaceBetween: 50,
                    },
                }}
                className="ReactItemSpinner"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation
                noSwipingSelector=".btn-red"
                onSlideChange={(slides) => {
                    setSlidePosition(slides?.realIndex);
                }}
                spaceBetween={50}
                watchSlidesProgress={true}
            >
                {orderableItems.map((orderableItem, index) => (
                    <SwiperSlide key={index}>
                        <div className={`slide${orderableItem.isDirectReplacement ? ' directReplacement' : ''}`}>
                            {orderableItem.isDirectReplacement && <div className="body">Direct Replacement</div>}
                            <div className="spinner-image-block">
                                <ImpressionTracker
                                    data={{
                                        dimension16: ordersService?.currentOrderNumber,
                                        index,
                                        item_id: orderableItem.item,
                                        item_list_name: gaItemListName,
                                    }}
                                    detectPartial={false}
                                    slidePosition={slidePosition}
                                    trackImpressions={false}
                                >
                                    <a
                                        className="link"
                                        href={`/item/${orderableItem.item}`}
                                        onClick={() => {
                                            localStorageService.removeItem(`searchTerm`);
                                            clickEvents(orderableItem.recToken);
                                        }}
                                    >
                                        <img
                                            alt={replaceHTMLQuotes(orderableItem.description)}
                                            className="spinner-image"
                                            src={`/ProductImageThumbs120${orderableItem.imagePath || '/noimage.png'}`}
                                        />
                                    </a>
                                </ImpressionTracker>
                            </div>
                            <div className="mb-1">
                                <Price
                                    alias={componentName}
                                    item={orderableItem}
                                    user={user}
                                />
                                <div className="caption d-flex flex-column">
                                    <div>Item: {orderableItem.prettyItem ? orderableItem.prettyItem : orderableItem.item}</div>
                                    <div>Pkg Qty: {orderableItem.pkgQty}</div>
                                </div>
                            </div>
                            <div
                                className="caption"
                                style={{minHeight: 42}}
                            >
                                <ImpressionTracker
                                    data={{
                                        dimension16: ordersService?.currentOrderNumber,
                                        index,
                                        item_id: orderableItem.item,
                                        item_list_name: gaItemListName,
                                        recToken: orderableItem.recToken,
                                    }}
                                    slidePosition={slidePosition}
                                >
                                    <a
                                        className="link"
                                        href={`/item/${orderableItem.item}`}
                                        onClick={() => {
                                            localStorageService.removeItem(`searchTerm`);
                                            clickEvents(orderableItem.recToken);
                                        }}
                                    >
                                        {orderableItem.description}
                                    </a>
                                </ImpressionTracker>
                            </div>
                            <div className="form-inline mt-3">
                                <input
                                    aria-label="Add to Order Quantity"
                                    className="ImpItem_cartOptionsQty form-control mr-2"
                                    defaultValue={1}
                                    id={`cart-qty-${orderableItem.item}`}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    type="number"
                                />
                                <button
                                    className="btn btn-red"
                                    onClick={() => {
                                        clickEvents(orderableItem.recToken);
                                        const unitsOrderedValue = parseInt(
                                            (document.getElementById(`cart-qty-${orderableItem.item}`) as HTMLInputElement).value,
                                        );
                                        if (!user.isLoggedIn() && orderableItem.excludeStArray) {
                                            setShipRestrictionsModal({
                                                show: true,
                                                orderableItem,
                                                unitsOrdered: unitsOrderedValue,
                                            });
                                        } else {
                                            localStorageService.removeItem(`searchTerm`);
                                            addToOrder(
                                                [
                                                    {
                                                        item: orderableItem.item,
                                                        list: gaItemListName,
                                                        unitsOrdered: unitsOrderedValue,
                                                    },
                                                ],
                                                null,
                                                true,
                                                useAddToOrderModal,
                                            );
                                        }
                                    }}
                                    type="button"
                                >
                                    Add to Order
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <ErrorModal
                errorMsg={errorModal.errorMsg}
                onClose={() => setErrorModal({show: false, errorMsg: ``})}
                show={errorModal.show}
            />
            <ShipRestrictionsModal
                addToOrder={addToOrder}
                gaItemListName={gaItemListName}
                onHide={() =>
                    setShipRestrictionsModal({
                        orderableItem: undefined,
                        show: false,
                        unitsOrdered: 1,
                    })
                }
                orderableItem={shipRestrictionsModal.orderableItem}
                show={shipRestrictionsModal.show}
                unitsOrdered={shipRestrictionsModal.unitsOrdered}
                useAddToOrderModal={useAddToOrderModal}
            />
        </>
    );
};

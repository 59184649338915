/**
 * Returns true if the user has opted out of tracking
 * @param optanonConsentCookie - Cookie to parse
 */
export const optedOutOfTracking = (optanonConsentCookie: string): boolean => {
    return optanonConsentCookie.indexOf(`C0007:0`) > -1 || optanonConsentCookie.indexOf(`C0007%3A0`) > -1;
};

/**
 * Displays LiveChat chat window
 */
export const showChatWindow = () => {
    if (window && (window as any).LC_API) {
        (window as any).LC_API.open_chat_window();
    }
};

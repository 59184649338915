import * as React from 'react';
import {faAngleLeft, faBarcodeRead, faCircleUser, faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useMemo, useState} from 'react';

import {ByServicePageMeta} from '../../shop-by-service/byService.type';
import {HeaderAccountInfo} from '../header-account-info/HeaderAccountInfo';
import {HeaderMobileMenu} from '../header-mobile-menu/HeaderMobileMenu';
import {HeaderOrderButton} from '../header-order-button/HeaderOrderButton';
import {MegaMenu} from '../mega-menu/MegaMenu';
import {MyAccountFlyout} from '../my-account-flyout/MyAccountFlyout';
import {OrderMenuFlyout} from '../order-menu-flyout/OrderMenuFlyout';
import {PrismicContent} from '../../cms/cms.types';
import {ScannerWorkflow} from '../../../client/scanner/scanner.workflow';
import {SearchOverlay} from '../search-overlay/SearchOverlay';
import {useBeforeunload} from '../../tools/use-beforeunload.hook';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import {WebcatMeta} from '../../items/item.class';
import Button from '../../ui/Buttons/Button';

export interface SiteHeaderProps {
    byServiceList: ByServicePageMeta[];
    megaMenuContent: PrismicContent;
    originalUrl?: string;
    rootCategories: WebcatMeta[];
    searchString: string;
    showSearchBarDefault: boolean;
    userData: User;
}

export const SiteHeader = ({
    byServiceList,
    megaMenuContent,
    originalUrl,
    rootCategories,
    searchString,
    showSearchBarDefault,
    userData,
}: SiteHeaderProps) => {
    const [isNavigating, setIsNavigating] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(!!showSearchBarDefault);
    const componentName = `SiteHeader`;
    const isBrowseSearch = originalUrl?.startsWith(`/browse`) || originalUrl?.startsWith(`/search`);
    const scannerWorkflow: ScannerWorkflow = useService(`scannerWorkflow`);
    const user = useMemo(() => new User(userData), [userData]);
    const userStateService: UserStateService = useService(`userStateService`);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAction(componentName, `showScanner`)) {
            scannerWorkflow.showScanner(componentName, `header`);
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Show progress bar when navigating
    useBeforeunload(() => {
        setIsNavigating(true);
    });

    /**
     * Template
     */
    return (
        <>
            <HeaderAccountInfo user={user} />
            <div
                className="tw-flex tw-justify-between tw-items-center lg:tw-hidden tw-bg-gray-100 tw-h-[54px]"
                id="mobileHeader"
            >
                <div className="tw-flex tw-items-center">
                    <a
                        aria-label="Back Arrow"
                        className="tw-pr-3 tw-pl-4 tw-text-gray-650 tw-hidden"
                        onClick={() => history.back()}
                        role="button"
                        style={{color: `#333`}}
                    >
                        <FontAwesomeIcon
                            icon={faAngleLeft}
                            size="lg"
                        />
                    </a>
                    <a
                        href="/"
                        id="header_imperialLogo"
                    >
                        <img
                            alt="Imperial Logo"
                            className="tw-ml-4 tw-tw-max-w-[80px]"
                            src="/dimg/imperial_logo_rgb.svg"
                            width="80"
                        />
                    </a>
                </div>
                <div className="tw-flex tw-items-center">
                    <a
                        aria-label="Search Icon"
                        className="tw-p-3 tw-text-gray-650"
                        onClick={() => setShowSearchBar(!showSearchBar)}
                        role="button"
                        style={{color: `#333`}}
                    >
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            size="lg"
                        />
                    </a>
                    <a
                        aria-label="Barcode Icon"
                        className="tw-p-3 tw-text-gray-650"
                        onClick={() => scannerWorkflow.showScanner(componentName, `hamburger_menu`)}
                        role="button"
                        style={{color: `#333`}}
                        tabIndex={0}
                    >
                        <FontAwesomeIcon
                            icon={faBarcodeRead}
                            size="lg"
                        />
                    </a>
                    <HeaderOrderButton
                        hasActiveOrder={user.hasActiveOrder()}
                        numOrderLines={parseInt(user.orderLines)}
                    />
                    <HeaderMobileMenu user={user} />
                </div>
            </div>
            {showSearchBar && (
                <div className="tw-flex lg:tw-hidden tw-bg-gray-100 tw-w-full tw-items-center tw-pt[1px] tw-px-2 tw-pb-2">
                    <div className="tw-w-full">
                        <SearchOverlay
                            dataE2e="searchBarInputMobile"
                            initialSearchString={searchString}
                            screenSize="mobile"
                            user={user}
                        />
                    </div>
                    {isBrowseSearch && (
                        <Button
                            className="tw-py-1.5 tw-px-3 tw-ml-2"
                            onClick={() => (window as any).toggleBrowseFilters()}
                            size="sm"
                            type="button"
                            variant="outline-secondary"
                        >
                            Filter
                        </Button>
                    )}
                </div>
            )}
            <div className="tw-bg-gray-100">
                <div className="tw-hidden lg:tw-flex tw-items-center tw-w-full tw-max-w-[1432px] tw-mx-auto tw-py-0 tw-px-4">
                    <div
                        className="tw-flex tw-items-center"
                        data-e2e="desktopImperialLogo"
                    >
                        <a
                            className="tw-mr-8 tw-py-3"
                            href="/"
                            id="header_imperialLogo"
                        >
                            <img
                                alt="Imperial Logo"
                                className="lg:tw-w-[120px] lg:tw-max-w-[120px] xl:tw-w-[177px] xl:tw-max-w-[177px]"
                                src="/dimg/imperial_logo_rgb.svg"
                            />
                        </a>
                    </div>
                    <div className="tw-flex tw-flex-col tw-w-full">
                        <div className="tw-hidden xl:tw-flex tw-flex-grow tw-flex-1 tw-mt-3">
                            <div className="tw-text-xl tw-text-gray-600 tw-mb-3 tw-font-bold">
                                Fleet Maintenance Supply Experts&nbsp;&nbsp;1-800-558-2808
                            </div>
                        </div>
                        <div className="tw-flex tw-whitespace-nowrap tw-bg-gray-100 tw-items-center tw-mb-0">
                            <div className="tw-mb-0 lg:tw-mr-12 xl:tw-mb-6 tw-flex-1">
                                <div className="tw-w-full">
                                    <SearchOverlay
                                        dataE2e="searchBarInputDesktop"
                                        initialSearchString={searchString}
                                        screenSize="desktop"
                                        user={user}
                                    />
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-mb-0 xl:tw-mb-4 tw-text-xl tw-h-[48px]">
                                <div className="tw-ml-8">
                                    <a
                                        className="tw-flex tw-items-center hover:tw-no-underline"
                                        data-position="header"
                                        onClick={() => scannerWorkflow.showScanner(componentName, `header`)}
                                        rel="nofollow"
                                        role="button"
                                        style={{color: `#333`}}
                                        tabIndex={0}
                                    >
                                        <FontAwesomeIcon
                                            className="tw-mr-2"
                                            icon={faBarcodeRead}
                                            size="lg"
                                        />
                                        <span className="tw-text-base tw-mb-0">Scan</span>
                                    </a>
                                </div>
                                <div className="tw-ml-8">
                                    {user.isLoggedIn() ? (
                                        <MyAccountFlyout user={user} />
                                    ) : (
                                        <a
                                            className="tw-flex tw-items-center hover:tw-no-underline"
                                            href="/account"
                                            rel="nofollow"
                                            role="button"
                                            style={{color: `#333`}}
                                        >
                                            <FontAwesomeIcon
                                                className="tw-mr-2 tw-text-gray-600"
                                                icon={faCircleUser}
                                                size="lg"
                                            />
                                            <span className="tw-text-base">Account</span>
                                        </a>
                                    )}
                                </div>
                                <div
                                    className="tw-ml-8 tw-text-center"
                                    data-e2e="OrdersLink"
                                >
                                    <OrderMenuFlyout user={user} />
                                </div>
                                <div
                                    className="tw-ml-6"
                                    data-e2e="cartIcon"
                                >
                                    <HeaderOrderButton
                                        hasActiveOrder={user.hasActiveOrder()}
                                        numOrderLines={parseInt(user.orderLines)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isNavigating && (
                    <div className="tw-w-full lg:tw-hidden">
                        <div className="tw-h-1.5 tw-w-full tw-bg-red-100 tw-overflow-hidden">
                            <div className="tw-animate-progress tw-w-full tw-h-full tw-bg-red-500 tw-origin-left-right" />
                        </div>
                    </div>
                )}
            </div>
            <MegaMenu
                byServiceList={byServiceList}
                megaMenuContent={megaMenuContent}
                rootCategories={rootCategories}
                user={user}
            />
        </>
    );
};

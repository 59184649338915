import * as React from 'react';
import {Ref, FocusEvent} from 'react';
import {FormCheck, FormGroup} from 'react-bootstrap';

export type CheckBoxProps = {
    alias: string;
    ariaLabel?: string;
    caption?: string;
    checked?: boolean;
    className?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    error?: string;
    errorPosition?: `above` | `below`;
    inputClass?: string;
    label?: string | React.JSX.Element;
    labelClass?: string;
    name: string;
    noMargin?: boolean;
    onChange?: (e) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    ref?: Ref<unknown> | null;
    subLabel?: string;
    value?: string;
} & JSX.IntrinsicElements[`input`];

export const CheckBoxComponent = (
    {
        alias,
        ariaLabel,
        caption,
        checked,
        className,
        defaultChecked,
        disabled,
        error = ``,
        errorPosition = `below`,
        inputClass,
        label,
        labelClass,
        name,
        noMargin = false,
        onChange,
        onFocus,
        subLabel,
        value = ``,
    }: CheckBoxProps,
    ref,
) => (
    <FormGroup
        className={`custom-control custom-checkbox checkbox-lg${className ? ` ` + className : ``} ${noMargin ? 'tw-mb-0' : 'mb-3 mb-md-0'}`}
        controlId={name}
    >
        {errorPosition === `above` && error && <label className="form-label error ml-2 pl-1">{error}</label>}
        <FormCheck.Input
            aria-label={ariaLabel}
            checked={checked}
            className={`custom-control-input${error ? ` error` : ``}${inputClass ? ` ` + inputClass : ``}`}
            defaultChecked={defaultChecked}
            disabled={disabled}
            id={`${alias}_checkbox`}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            ref={ref}
            type="checkbox"
            value={value}
        />
        <FormCheck.Label
            className={`custom-control-label${labelClass ? ` ` + labelClass : ``}${subLabel ? ' center-radio' : ''}`}
            htmlFor={`${alias}_checkbox`}
        >
            {label ? label : `\u00A0`}
            {subLabel && <div className="caption">{subLabel}</div>}
        </FormCheck.Label>
        {errorPosition === `below` && error && <label className="form-label error ml-2 pl-1">{error}</label>}
        {caption && <div className="ml-2 pl-1 caption">{caption}</div>}
    </FormGroup>
);
export const CheckBox = React.forwardRef(CheckBoxComponent);

import * as React from 'react';
import {truncate} from 'lodash';

import PromotionTracker from '../analytics/promotion-tracker/PromotionTracker';
import {HomeZone2} from '../ads/ads.types';
import {PageHeader} from '../ui/elements/PageHeader';

interface HomeZoneTwoProps {
    homeZone2: HomeZone2;
}

export const HomeZoneTwo = ({homeZone2}: HomeZoneTwoProps) => {
    if (!homeZone2) {
        return <></>;
    }
    return (
        <div className="tw-mb-6">
            <PageHeader
                title={homeZone2.custom_name}
                type="h4"
            />
            <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-6 tw-gap-4">
                {homeZone2.specialItems.map((item, index) => (
                    <div
                        key={index}
                        className="tw-mb-3 tw-text-center tw-relative"
                    >
                        <div className="tw-border tw-rounded-[50%] tw-h-0 tw-pb-[100%] tw-w-full">
                            <div className="tw-p-4 tw-flex tw-items-center tw-justify-center">
                                <img
                                    alt={item.shortdesc}
                                    src={`/ProductImageThumbs240${item.imagePath || '/noimage.png'}`}
                                    className="tw-max-w-full tw-h-auto tw-p-4"
                                    width="240"
                                    height="240"
                                />
                            </div>
                        </div>
                        <PromotionTracker
                            promotion={{
                                creative_name: `Hand Curated Recos - ${homeZone2.destination_url} - ${
                                    homeZone2.destination_url === 'IDP' ? item.item : item.categoryName
                                }`,
                                creative_slot: index + 1,
                                promotion_id: homeZone2.specialNumber,
                                promotion_name: homeZone2.specialName,
                            }}
                        >
                            <a
                                className="after:tw-absolute after:tw-inset-0"
                                href={homeZone2.destination_url === 'IDP' ? '/item/' + item.item : '/browse/' + item.categoryUrl}
                            >
                                {homeZone2.destination_url === 'IDP'
                                    ? truncate(item.shortdesc, {length: 65})
                                    : truncate(item.categoryName, {length: 65})}
                            </a>
                        </PromotionTracker>
                    </div>
                ))}
            </div>
        </div>
    );
};

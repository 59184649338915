import * as React from 'react';
import {faShoppingCart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';

import {GaItemListName} from '../../client/ga/ga-ecommerce.functions';
import {gaLegacyCustomEvent} from '../../client/ga/ga-legacy.functions';
import {ImpError} from '../../client/imp-error/imp-error.class';
import {ImpUrl} from '../../client/imp-url/imp-url.class';
import {ItemToAdd} from '../order-items/order-items.class';
import {OrderItemsWorkflow} from '../../client/order-items/order-items.workflow';
import {useService} from '../react/ServiceContext';

interface TopProductsItemProps {
    closeMegaMenu: () => void;
    gaItemListName: GaItemListName;
    isQuickAdd?: boolean;
    itemNum: string;
    setAddToOrderError: React.Dispatch<React.SetStateAction<string>>;
}

export const TopProductsItem = ({closeMegaMenu, gaItemListName, isQuickAdd, itemNum, setAddToOrderError}: TopProductsItemProps) => {
    const [unitsOrdered, setUnitsOrdered] = useState(1);
    const componentName = `TopProductsItem`;
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);

    /**
     * Adds item to order
     * @param itemToAdd
     */
    const addItemToOrder = (itemToAdd: ItemToAdd) => {
        setAddToOrderError(``);

        // Record analytics
        gaLegacyCustomEvent({eventAction: `Add to Order Clicks`, eventCategory: `Ecommerce`, eventLabel: gaItemListName});

        // Perform add to order
        if (ImpUrl.onOrderDetailPage) {
            orderItemsWorkflow.addToOrderToast([itemToAdd], componentName, true, undefined, closeMegaMenu).subscribe({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                next: () => {},
                error: (addToOrderToastErr: ImpError) => {
                    setAddToOrderError(addToOrderToastErr.message);
                },
            });
        } else {
            orderItemsWorkflow.addToOrderModal([itemToAdd], componentName, true, closeMegaMenu);
        }
    };

    /**
     * Template
     */
    return (
        <div
            className={`item-add-to-cart form-inline ${
                isQuickAdd && 'tw-float-right tw-mt-5 tw-flex tw-flex-row tw-flex-wrap tw-items-center'
            }`}
        >
            <input
                className={`form-control mr-2 ${isQuickAdd && '!tw-w-10 tw-inline-block tw-align-middle'}`}
                inputMode="numeric"
                name={`${componentName}-${itemNum}-qty`}
                onChange={(e) => setUnitsOrdered(parseInt(e.target.value))}
                type="number"
                value={unitsOrdered}
            />
            <button
                className="btn btn-red"
                onClick={() => addItemToOrder({item: itemNum, list: gaItemListName, unitsOrdered})}
            >
                <FontAwesomeIcon icon={faShoppingCart} />
            </button>
        </div>
    );
};

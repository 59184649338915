import * as React from 'react';
import {faSearch, faShoppingCart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode} from 'react';

export type ButtonVariant =
    | `blue`
    | `disabled`
    | `light`
    | `link`
    | `outline-blue`
    | `outline-light`
    | `outline-primary`
    | `outline-secondary`
    | `primary`
    | `secondary`
    | `tab`;

interface ButtonProps {
    block?: boolean;
    children: ReactNode;
    className?: string;
    diagonal?: boolean;
    disabled?: boolean;
    e2e?: string;
    icon?: `cart` | `none` | `search`;
    id?: string;
    modal?: boolean;
    modalOrderChangeButton1?: boolean;
    modalOrderChangeButton2?: boolean;
    onClick?: (event?) => void;
    size?: `sm` | `md` | `lg` | `smToLg`;
    rounded?: boolean;
    type?: `button` | `reset` | `submit`;
    variant?: ButtonVariant;
}

const buttonStyles = `tw-rounded tw-justify-center tw-items-center tw-relative tw-font-normal tw-text-center`;

const variantStyles: Record<ButtonVariant, any> = {
    blue: {
        button: `tw-bg-blue-800 tw-border tw-border-blue-800 hover:tw-bg-blue-900 tw-text-white`,
    },
    disabled: {
        button: `tw-opacity-50`,
    },
    light: {
        button: `tw-border tw-border-gray-200 tw-bg-gray-200 tw-text-gray-600 hover:tw-bg-gray-250`,
    },
    link: {
        button: `tw-text-blue-200 tw-bg-transparent`,
    },
    'outline-blue': {
        button: `tw-bg-white tw-text-blue-800 tw-border tw-border-blue-800 hover:tw-bg-blue-800 hover:tw-text-white`,
    },
    'outline-light': {
        button: `tw-border tw-border-gray-200 tw-bg-transparent active:tw-bg-gray-250 tw-text-gray-200 hover:tw-bg-gray-200 hover:tw-text-gray-600`,
    },
    'outline-primary': {
        button: `tw-border tw-border-red-500 tw-bg-white active:tw-bg-red-600 tw-text-red-500 hover:tw-bg-red-500 hover:tw-text-white`,
    },
    'outline-secondary': {
        button: `tw-border tw-border-gray-600 tw-bg-transparent hover:tw-bg-gray-600 tw-text-gray-650 hover:tw-text-white`,
    },
    primary: {
        button: `tw-bg-red-500 active:tw-bg-red-600 hover:tw-bg-red-600 tw-text-white`,
    },
    secondary: {
        button: `tw-border tw-border-gray-600 tw-bg-gray-600 hover:tw-bg-gray-650 tw-text-white`,
    },
    tab: {
        button: `tw-text-blue-200 tw-bg-transparent !tw-font-extrabold tw-px-4 tw-py-2 tw-h-auto !tw-text-base`,
    },
};

const Button: React.FC<ButtonProps> = ({
    block,
    children,
    className,
    disabled = false,
    e2e,
    icon = `none`,
    id,
    modal = false,
    modalOrderChangeButton1 = false,
    modalOrderChangeButton2 = false,
    onClick,
    rounded = false,
    diagonal = false,
    size,
    type = `button`,
    variant = `secondary`,
}) => {
    const {button} = variantStyles[variant];

    const btnSize = () => {
        switch (size) {
            case 'lg':
                return 'tw-px-2.5 tw-h-[52px] tw-text-xl';
            case 'md':
                return 'tw-px-2.5 tw-h-[48px] tw-text-xl';
            case 'sm':
                return 'tw-px-2 tw-h-[38px] tw-text-base';
            case 'smToLg':
                return 'tw-px-2 tw-h-[38px] tw-text-base md:tw-h-[48px] lg:tw-h-[48px] md:!tw-text-xl lg:!tw-text-xl tw-w-full lg:tw-w-auto';
            default:
                return 'tw-px-2.5 tw-h-[52px] tw-text-xl';
        }
    };

    const btnStyles = [
        buttonStyles,
        button,
        disabled ? variantStyles.disabled.button : null,
        block ? `tw-w-full` : `tw-w-auto`,
        modal
            ? `focus:tw-shadow-[0_0_0_0.2rem_rgba(108,117,125,0.5)] !tw-rounded tw-w-full lg:tw-w-auto tw-inline-block tw-px-4 tw-transition-colors tw-mb-2 tw-ease-in ${
                  size === 'smToLg' ? '' : '!tw-h-[48px] tw-text-xl tw-leading-normal '
              }`
            : ``,
        size === 'lg'
            ? `tw-px-2.5 tw-h-[52px] tw-text-xl`
            : `tw-text-base ${variant === 'link' ? '!tw-px-0 tw-h-auto' : 'tw-px-2 tw-h-[38px]'}`,
        rounded ? `tw-rounded` : `tw-rounded-sm`,
        modalOrderChangeButton1 ? `tw-order-2 lg:tw-order-1` : ``,
        modalOrderChangeButton2 ? `tw-order-1 lg:tw-order-2 !tw-mb-4 lg:!tw-ml-4` : ``,
        btnSize(),
    ]
        .filter(Boolean)
        .flat()
        .join(' ');

    return (
        <button
            className={`${className} ${btnStyles} tw-px-4`}
            data-e2e={e2e ? e2e : undefined}
            disabled={disabled}
            id={id}
            onClick={onClick}
            style={diagonal ? {backgroundImage: `url("../../../../staticAssets/cacheForever/dimg/diagonal_1px_424242.png")`} : {}}
            type={type}
        >
            {icon !== `none` ? <FontAwesomeIcon icon={icon === `search` ? faSearch : faShoppingCart} /> : <>{children}</>}
        </button>
    );
};

export default Button;

import * as React from 'react';
import {faFacebookSquare, faYoutubeSquare, faLinkedin, faSquareXTwitter} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';

import Button from '../../ui/Buttons/Button';
import {NavSegment} from './NavSegment';
import {PrismicContent} from '../../cms/cms.types';
import {SubscribePromosModal} from '../../net-results/subscribe-promos-modal/SubscribePromosModal';

export interface FooterProps {
    copyrightYear: string;
    disableRecaptcha: boolean;
    globalFooter?: PrismicContent;
    isNativeApp: boolean;
}

export const Footer = ({copyrightYear, disableRecaptcha, globalFooter, isNativeApp}: FooterProps) => {
    const [openSegmentIndex, setOpenSegmentIndex] = useState<number>();
    const [showSubscribePromosModal, setShowSubscribePromosModal] = useState(false);

    /**
     * TBD
     * @param segmentIndex
     */
    const handleSegmentClick = (segmentIndex: number) => {
        if (openSegmentIndex === segmentIndex) {
            setOpenSegmentIndex(undefined);
        } else {
            setOpenSegmentIndex(segmentIndex);
        }
    };

    /**
     * Template
     */
    return (
        <>
            <div className="main-footer tw-border-t-[5px] tw-border-gray-600 tw-bg-gray-100 tw-text-base tw-mt-4">
                <div className="lg:tw-container lg:tw-mx-auto">
                    <div className="lg:tw-grid lg:tw-grid-cols-4 lg:tw-mx-3">
                        {globalFooter?.body?.map((navSegments, index) => (
                            <NavSegment
                                key={index}
                                navSegment={navSegments}
                                onClick={handleSegmentClick}
                                openSegmentIndex={openSegmentIndex}
                                segmentIndex={index}
                            />
                        ))}
                    </div>
                    <div className="tw-border-b tw-mb-4" />
                </div>
                <div className="lg:tw-container tw-mx-3 lg:tw-mx-auto">
                    <div className="lg:tw-grid lg:tw-grid-cols-3">
                        <div className="tw-mb-4 md:tw-text-center">
                            <div className="!tw-font-extrabold tw-text-xl tw-mb-4">Connect with us. | 1-800-558-2808</div>
                            <div>
                                <a
                                    aria-label="Facebook Link"
                                    className="tw-text-gray-700 tw-mr-5 hover:tw-no-underline"
                                    href="https://www.facebook.com/pages/Imperial-Supplies/160873310666103"
                                    id="Facebook"
                                    role="button"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon
                                        className="tw-text-[54px]"
                                        icon={faFacebookSquare}
                                        suppressHydrationWarning
                                    />
                                </a>
                                <a
                                    aria-label="Twitter Link"
                                    className="tw-text-gray-700 tw-mr-5 hover:tw-no-underline"
                                    href="https://twitter.com/#!/Imperial_Tweets"
                                    id="Twitter"
                                    role="button"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon
                                        className="tw-text-[54px]"
                                        icon={faSquareXTwitter}
                                        suppressHydrationWarning
                                    />
                                </a>
                                <a
                                    aria-label="YouTube Link"
                                    className="tw-text-gray-700 tw-mr-5 hover:tw-no-underline"
                                    href="https://www.youtube.com/user/ImperialSupplies"
                                    id="YouTube"
                                    role="button"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon
                                        className="tw-text-[54px]"
                                        icon={faYoutubeSquare}
                                        suppressHydrationWarning
                                    />
                                </a>
                                <a
                                    aria-label="LinkedIn Link"
                                    className="tw-text-gray-700 tw-mr-0 hover:tw-no-underline"
                                    href="https://www.linkedin.com/company/imperial-supplies"
                                    id="LinkedIn"
                                    role="button"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon
                                        className="tw-text-[54px]"
                                        icon={faLinkedin}
                                        suppressHydrationWarning
                                    />
                                </a>
                            </div>
                        </div>
                        {!isNativeApp && (
                            <div className="tw-mb-4 md:tw-text-center">
                                <div className="!tw-font-extrabold tw-text-xl tw-mb-4">Try the Mobile App.</div>
                                <div className="tw-flex md:tw-justify-center">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.imperial.ImperialApp"
                                        className="tw-no-underline"
                                        id="GooglePlay"
                                        target="_blank"
                                    >
                                        <img
                                            alt="Google Play Store Icon"
                                            className="tw-max-w-full tw-h-auto tw-max-h-12"
                                            src="/dimg/google-play@2x.png"
                                        />
                                    </a>
                                    <a
                                        className="tw-no-underline tw-ml-2 md:tw-ml-3"
                                        href="https://itunes.apple.com/us/app/imperial-supplies/id775108692?ls=1&mt=8"
                                        id="AppStore"
                                        target="_blank"
                                    >
                                        <img
                                            alt="Apple App Store Icon"
                                            className="tw-max-w-full tw-h-auto tw-max-h-12"
                                            src="/dimg/app-store@2x.png"
                                        />
                                    </a>
                                </div>
                            </div>
                        )}
                        <div className="tw-mb-4 md:tw-text-center">
                            <div className="!tw-font-extrabold tw-text-xl tw-mb-1">Stay Updated.</div>
                            <div className="tw-mb-2">Don't miss out on exclusive offers and expert tips.</div>
                            <Button
                                onClick={() => setShowSubscribePromosModal(true)}
                                size="sm"
                            >
                                Sign Up Today!
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className="tw-bg-gray-600 tw-text-gray-100 tw-py-1"
                    id="bottomFooter"
                >
                    <div className="lg:tw-container lg:tw-mx-auto">
                        <div className="tw-mb-3 tw-leading-6 tw-text-center">
                            <ul className="tw-list-none tw-mt-5 tw-mb-2.5 tw-p-0 tw-text-center tw-mx-auto">
                                <li className="tw-inline tw-border-r tw-border-r-gray-100 tw-py-0 tw-pl-[15px] tw-pr-2.5">
                                    <a
                                        className="tw-no-underline !tw-text-gray-100"
                                        href="/content/privacy"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li className="tw-inline tw-border-r tw-border-r-gray-100 tw-py-0 tw-pl-[15px] tw-pr-2.5">
                                    <a
                                        className="tw-no-underline !tw-text-gray-100"
                                        href="/content/terms"
                                    >
                                        Terms of Sale
                                    </a>
                                </li>
                                <li className="tw-inline tw-border-r tw-border-r-gray-100 tw-py-0 tw-pl-[15px] tw-pr-2.5">
                                    <a
                                        className="tw-no-underline !tw-text-gray-100"
                                        href="/content/terms-of-access"
                                    >
                                        Terms of Access
                                    </a>
                                </li>
                                <li
                                    className="tw-inline tw-border-r tw-border-r-gray-100 tw-py-0 tw-pl-[15px] tw-pr-2.5"
                                    id="myPersonalInformation"
                                >
                                    <button
                                        className="tw-text-gray-100 ot-sdk-show-settings tw-cursor-pointer"
                                        id="ot-sdk-btn"
                                    >
                                        Your Privacy Choices
                                    </button>
                                </li>
                                <li className="tw-inline tw-border-r tw-border-r-gray-100 tw-py-0 tw-pl-[15px] tw-pr-2.5">
                                    <a
                                        className="tw-no-underline !tw-text-gray-100"
                                        href="/content/shipping-saver-tc"
                                    >
                                        Shipping Saver
                                    </a>
                                </li>
                                <li className="tw-inline tw-py-0 tw-pl-[15px] tw-pr-2.5">
                                    <a
                                        className="tw-no-underline !tw-text-gray-100"
                                        href="/sitemap"
                                    >
                                        Site Map
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="tw-mb-3 tw-text-center">
                            <div className="tw-text-gray-900 tw-flex tw-justify-center">
                                <img
                                    alt="Visa Card Icon"
                                    className="[&_.fa]:tw-text-4xl tw-w-12"
                                    src="/dimg/visa@2x.png"
                                />
                                <img
                                    className="[&_.fa]:tw-text-4xl tw-w-12 tw-mx-2"
                                    alt="Master Card Icon"
                                    src="/dimg/mastercard@2x.png"
                                />
                                <img
                                    alt="American Express Icon"
                                    className="[&_.fa]:tw-text-4xl tw-w-12"
                                    src="/dimg/amex@2x.png"
                                />
                            </div>
                        </div>
                        <div className="tw-mb-3 tw-text-center">
                            <div>&copy; {copyrightYear} Imperial Supplies LLC. All Rights Reserved.</div>
                            <div className="tw-text-sm tw-font-normal tw-leading-[1.3]">
                                This site is protected by reCAPTCHA and the Google{' '}
                                <a
                                    className="tw-text-gray-100 tw-no-underline"
                                    href="https://policies.google.com/privacy"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a
                                    className="tw-text-gray-100 tw-no-underline"
                                    href="https://policies.google.com/terms"
                                    target="_blank"
                                >
                                    Terms of Service
                                </a>{' '}
                                apply.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SubscribePromosModal
                disableRecaptcha={disableRecaptcha}
                onClose={() => setShowSubscribePromosModal(false)}
                show={showSubscribePromosModal}
            />
        </>
    );
};

import {FetchService} from '../fetch/fetch.service';
import {ImpError} from '../imp-error/imp-error.class';
import {InvoiceItem} from '../../shared/invoices/invoice.class';
import {ProductSuggestion} from '../../shared/search/search-suggestions/search-suggestions.class';
import {Result} from '../../shared/search/search-result/search-result.class';
import {SessionStorageService} from '../session-storage/session-storage.service';
import {ShareLinkParams} from '../../shared/share-link/share-link.class';
import {StockStatus} from '../../shared/items/mro-status.class';
import {UsersService} from '../users/users.service';
import {User} from '../../shared/users/user.class';
import {
    CrossRefLookupItem,
    CrossRefLookupSource,
    GetTopProductResponse,
    Item,
    ItemImages,
    ManyPrices,
    OrderableItem,
    OrderableItemsRes,
    ReStockEmailParams,
    ReStockEmailRes,
    ShareItemRes,
    SwitchAndSaveItem,
    TopProduct,
    WebcatMeta,
} from '../../shared/items/item.class';

function hashCode(str: string) {
    return Math.abs(str.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0));
}

export class ItemsService {
    private _currentUser: User = null;

    constructor(
        private _fetchService: FetchService,
        private _sessionStorageService: SessionStorageService,
        private _usersService: UsersService,
    ) {
        this._currentUser = this._usersService.getCurrentUser();
    }

    /**
     * Takes an array of item id's and returns their price info
     * @param items - Array of items types to add price to
     */
    public addPricesForItemTypeArray(items: InvoiceItem[] | ProductSuggestion[] | Result[] | TopProduct[]): Promise<typeof items> {
        return new Promise((resolve, reject) => {
            // If not logged in or cannot view price return the unmodified item types
            if (!this._currentUser.isLoggedIn() || !this._currentUser.canViewPrice()) {
                resolve(items);
                return;
            }

            if (items.length === 0) {
                resolve(items);
                return;
            }

            // Build array of item id's for API call
            const itemNums = [];
            for (const itemType of items) {
                itemNums.push(itemType.id);
            }

            // Get prices and add to item types
            this.getManyPrices(itemNums)
                .then((getManyPricesRes) => {
                    // Add prices to item types array
                    for (const itemType of items) {
                        itemType.priceInfo = getManyPricesRes[itemType.id];
                    }
                    resolve(items);
                })
                .catch((getManyPricesResErr: ImpError) => {
                    reject(getManyPricesResErr);
                });
        });
    }

    /**
     * Returns Item for provided itemNum
     * @param itemNum
     */
    public getItemDetail(itemNum: string) {
        return this._fetchService.get<Item>(`/api/Items/detail/${encodeURIComponent(itemNum)}`);
    }

    /**
     * Get Price for several Items
     * @param itemNums
     */
    public getManyPrices(itemNums: string[]) {
        return this._fetchService.post<ManyPrices>(`/api/Items/getManyPrices`, {itemNums});
    }

    /**
     * Gets users previously purchased items
     */
    public getPrevPurchasedItems() {
        return this._fetchService.get<OrderableItem[]>(`/api/Items/previousPurchasedItems`);
    }

    /**
     * Get Root Categories for user
     */
    public getRootCategories() {
        return new Promise<WebcatMeta[]>((resolve, reject) => {
            // Build alias for local storage
            const alias = this._currentUser.isLoggedIn()
                ? `${hashCode(this._currentUser.loginName)}_${this._currentUser.activeCatalogId}`
                : `anonymous`;

            // Use cache if available
            if (this._sessionStorageService.getAliasedItem(`getRootCategories`, alias)) {
                resolve(JSON.parse(this._sessionStorageService.getAliasedItem(`getRootCategories`, alias)));
            } else {
                // Else retrieve new product categories
                this._fetchService
                    .get<WebcatMeta[]>(`/api/Items/getRootCategories`)
                    .then((getRootCategoriesRes) => {
                        this._sessionStorageService.setAliasedItem(`getRootCategories`, alias, JSON.stringify(getRootCategoriesRes));
                        resolve(getRootCategoriesRes);
                    })
                    .catch((getRootCategoriesErr: ImpError) => {
                        reject(getRootCategoriesErr);
                    });
            }
        });
    }

    /**
     * Get OrderableItems for requested item array
     * @param itemNums - Array of Item IDs to load
     * @param includeDiscontinuedItems
     */
    public getOrderableItems(itemNums: string[], includeDiscontinuedItems?: boolean) {
        return this._fetchService.post<OrderableItemsRes>(`/api/items/getOrderableItems`, {
            crossRefLookup: true,
            followSubstitutes: true,
            ignorePandemicFlag: true,
            includeDiscontinuedItems: !!includeDiscontinuedItems,
            includeNotFoundItems: true,
            itemNums,
        });
    }

    /**
     * Returns secondary images for provided itemNum
     * @param itemNum
     */
    public getSecondaryImages(itemNum: string) {
        return this._fetchService.get<ItemImages>(`/api/Items/images/${encodeURIComponent(itemNum)}`);
    }

    /**
     * Gets stock status for provided itemNum
     * @param itemNum - Item to get stock status for
     * @param units - Units to order
     * @param zipcode - Ship to postal code
     * @param isMro - If item is MRO or not
     */
    public getStockStatus(itemNum: string, units: number, zipcode: string, isMro: boolean) {
        const url = isMro ? `/api/Items/getMROStatus` : `/api/Items/getCoreStatus`;
        return this._fetchService.get<StockStatus>(
            `${url}?item=${encodeURIComponent(itemNum)}&zipCode=${encodeURIComponent(zipcode)}&units=${units}`,
        );
    }

    /**
     * Gets substitute items for provided itemNum
     * @param itemNum - Item number for which to find substitutes
     */
    public getSubstituteItems(itemNum: string) {
        return this._fetchService.get<OrderableItem[]>(`/api/Items/getSubstituteItems/${itemNum}`);
    }

    /**
     * Gets SwitchAndSaveItem from provided ItemNum if exists
     * @param itemNum - Item number to check for switch and save
     */
    public getSwitchAndSaveItem(itemNum: string) {
        return this._fetchService.get<SwitchAndSaveItem>(`/api/Items/getSwitchAndSaveItem/${itemNum}`);
    }

    /**
     * Get top products for user
     */
    public getTopProducts() {
        return this._fetchService.get<GetTopProductResponse>(`/api/Items/topProducts`);
    }

    /**
     * Returns cross-reference numbers for provided itemNum
     * @param itemNum - Item to get cross-references for
     * @param source - Request source of xrefs (controls results)
     */
    public getXRefs(itemNum: string, source?: CrossRefLookupSource) {
        // Build URL
        let url = `/api/Items/getXRefs?itemNum=${itemNum}`;
        if (source) {
            url += `&source=${source}`;
        }

        // Get cross-references
        return this._fetchService.get<CrossRefLookupItem[]>(url);
    }

    /**
     * Find an array of orderable items for the user's recent item views
     */
    public recentlyViewedItems() {
        return this._fetchService.get<OrderableItem[]>(`/api/items/recentlyViewedItems`);
    }

    /**
     * Sends notification email when item is back in stock
     * @param reStockEmailParams
     */
    public restockEmail(reStockEmailParams: ReStockEmailParams) {
        return this._fetchService.post<ReStockEmailRes>(`/api/items/restockEmail`, reStockEmailParams);
    }

    /**
     * Shares item
     * @param shareItemParams
     */
    public shareItem(shareItemParams: ShareLinkParams) {
        return this._fetchService.post<ShareItemRes>(`/api/items/shareItem`, shareItemParams);
    }
}

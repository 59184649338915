import {allStates} from '../../contacts/state-select/all-states.const';
import {usStates} from '../../contacts/state-select/us-states.const';
import {VocCondition, VocType} from '../../orders/order.types';

interface DropDownOption {
    key: string;
    value: string;
}

/**
 * Provides drop down key/value pairs for Canadian and US states
 */
export const allStatesOptions = () => {
    const options: DropDownOption[] = [];
    allStates.forEach((state) =>
        options.push({
            key: state.id,
            value: state.id,
        }),
    );
    return options;
};

/**
 * TBD
 * @param vocTypes
 */
export const selectTypesOptions = (vocTypes: VocType[]) => {
    const types: DropDownOption[] = [{key: '', value: 'Select One'}];
    vocTypes.map((vocType) => {
        types.push({
            key: vocType.type,
            value: vocType.typeDesc,
        });
    });
    return types;
};

/**
 * TBD
 * @param vocConditions
 */
export const selectConditionOptions = (vocConditions: VocCondition[]) => {
    const conditions: DropDownOption[] = [{key: '', value: 'Select One'}];
    {
        vocConditions.map((condition) => {
            conditions.push({
                key: condition.condition,
                value: condition.conditionDesc,
            });
        });
        return conditions;
    }
};

/**
 * Provides drop down key/value pairs for US states
 */
export const usStatesOptions = () => {
    const options: DropDownOption[] = [];
    usStates.forEach((state) =>
        options.push({
            key: state.id,
            value: state.name,
        }),
    );
    return options;
};
